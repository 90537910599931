import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router';
import Home from '../views/Home';
import QR from '../views/QR';
import Sheet from '../views/Sheet';
import UploadSheet from '../views/UploadSheet';
import QrScan from '../views/QrScan';
import NotFound from '../views/NotFound';
import Viewer from '../views/Viewer';
import SheetPrinting from '../views/SheetPrinting';
import StudentSheetPrinting from '../views/StudentSheetPrinting';
import SheetSubj from '../views/SheetSubj';
import PrivateRoute from '../components/PrivateRoute';
import CustomSheetPrinting from '../views/CustomSheetPrinting';
import MigrateBranchSheet from '../views/MigrateBranchSheet';
import PlanList from '../views/PlanList';
import PlanManagement from '../views/PlanManagement';
import RedirectPage from '../views/Redirect';
import Unauthorized from '../views/Unauthorized';

const routes: (RouteProps & { public?: boolean })[] = [
  {
    path: '/',
    component: () => <Redirect exact from="/" to="sheetlist" />,
    exact: true,
  },
  { path: '/home', component: Home, exact: true },
  { path: '/qr', component: QR, exact: true },
  { path: '/sheetlist', component: SheetSubj, exact: true },
  { path: '/sheetlist/:subj', component: Sheet, exact: true },
  { path: '/sheetlist/info/:id', component: UploadSheet, exact: true },
  { path: '/qrscan/:id/:name', component: QrScan, exact: true },
  { path: '/viewer/:sheetId/:sheetName', component: Viewer, exact: true },
  { path: '/sheetprinting', component: SheetPrinting, exact: true },
  {
    path: '/sheetprinting/:studentId/custom',
    component: CustomSheetPrinting,
    exact: true,
  },
  {
    path: '/sheetprinting/:studentId',
    component: StudentSheetPrinting,
    exact: true,
  },
  {
    path: '/migratebranch',
    component: MigrateBranchSheet,
    exact: true,
    public: true,
  },
  {
    path: '/planlist',
    component: PlanList,
    exact: true,
  },
  {
    path: '/planlist/:planId',
    component: PlanManagement,
    exact: true,
  },
  {
    path: '/redirect',
    component: RedirectPage,
    exact: true,
    public: true,
  },
  { path: '/401', component: Unauthorized, exact: true, public: true },
  { component: NotFound, public: true },
];

export default routes.map((props, index) => {
  return props.public ? (
    <Route key={index} {...props} />
  ) : (
    <PrivateRoute key={index} {...props} />
  );
});
