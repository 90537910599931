import React, { useRef, useEffect } from 'react';
import styles from './PdfPage.module.scss';
import { PDFDocumentProxy } from 'pdfjs-dist';

interface PdfPageProps {
  pdf: PDFDocumentProxy;
  page: number;
  fullView?: boolean;
}

export default (props: PdfPageProps) => {
  const { pdf, page, fullView } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const generatePage = () => {
    pdf.getPage(page).then(pdfPage => {
      const canvas = canvasRef.current;
      if (canvas) {
        const windowW = window.innerWidth;
        const unscaleviewport = pdfPage.getViewport(1);
        let scale = Math.min(windowW, 1000) / unscaleviewport.width;
        if (fullView) {
          scale = windowW / 2.2 / unscaleviewport.width;
        }
        const scaleviewport = pdfPage.getViewport(scale);
        canvas.width = Math.floor(scaleviewport.width);
        canvas.height = Math.floor(scaleviewport.width * Math.sqrt(2)) - 5;
        const context = canvas.getContext('2d') as CanvasRenderingContext2D;
        const renderContext = {
          canvasContext: context,
          viewport: scaleviewport,
        };
        pdfPage.render(renderContext);
      }
    });
  };

  useEffect(() => {
    generatePage();
  }, [pdf, page]);

  return (
    <div className={styles.page}>
      <canvas ref={canvasRef} className={styles.page}></canvas>
    </div>
  );
};
