import axios from 'axios';
import { Subject, SheetInfo } from '../state/ducks/sheet/types';

export interface StudentInfo {
  ID: number;
  email: string;
  nickname: string;
  firstname: string;
  lastname: string;
  nicknameEn: string;
  firstnameEn: string;
  lastnameEn: string;
  grade: number;
  parentPhone: string;
  studentPhone: string;
  school: string;
  studentState: string;
  userStatus: string;
}

export interface Student {
  ID: number;
  firstname: string;
  firstnameEn: string;
  lastname: string;
  lastnameEn: string;
  nickname: string;
  nicknameEn: string;
}

export interface ActiveStudent {
  students: Student[];
}

export interface VideoUrl {
  contentType: string;
  signedUrl: string;
}

export interface Portfolio {
  sheet: SheetInfo;
  videoUrl: VideoUrl | null;
}

export interface Portfolios {
  sheets: Portfolio[];
}

/**
 * Get student info
 * @param studentId
 */
export const studentInfo = (studentId: number | string) => {
  return axios.get<StudentInfo>(
    `${process.env.REACT_APP_API_URL}/v1/studentInfo/${studentId}`,
    {
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    }
  );
};

/**
 * Get all active student
 */
export const allStudent = () => {
  return axios.get<ActiveStudent>(
    `${process.env.REACT_APP_API_URL}/v1/user/activeStudent`,
    {
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    }
  );
};

/**
 * Print sheet from urls
 * @param sheet
 */
export const printSheet = (sheet: String[]) => {
  return axios.post(
    'https://asia-southeast2-monkeyproduction.cloudfunctions.net/print',
    { urls: sheet }
  );
};

/**
 * Get portfolio (Deprecated)
 * @param studentId
 * @param subject
 */
export const getPortfolio = (studentId: number | string, subject?: Subject) => {
  if (subject) {
    return axios.get<Portfolios>(
      `${process.env.REACT_APP_PORT_URL}/temp-port/${studentId}`,
      { params: { subject } }
    );
  } else {
    return axios.get<Portfolios>(
      `${process.env.REACT_APP_PORT_URL}/temp-port/${studentId}`
    );
  }
};

/**
 * Add portfolio (Deprecated)
 * @param studentId
 * @param sheetId
 */
export const addPortfolio = (studentId: number | string, sheetId: string) => {
  return axios.post(`${process.env.REACT_APP_PORT_URL}/temp-port`, {
    studentId,
    sheetId,
  });
};

/**
 * Add video to student in live platform
 * @param studentId
 * @param sheetId
 */
export const addVideoToStudent = async (
  studentId: number | string,
  sheetId: string
) => {
  const authData = await axios.post(
    `https://officeapi.monkeyatc.com/graphql`,
    {
      query: `
        query($username: String!) {
          user(username: $username) {
            id
            authId
            username
            firstName
            lastName
            nickname
            position
          }
        }
      `,
      variables: {
        username: studentId,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${window.sessionStorage.getItem('token') ||
          '1111'}`,
      },
    }
  );
  const authId = authData.data.data.user.authId;
  await axios.post(
    `https://officeapi.monkeyatc.com/allow-video`,
    {
      sheetId,
      authId,
    },
    {
      headers: {
        authorization: `Bearer ${window.sessionStorage.getItem('token') ||
          '1111'}`,
      },
    }
  );
};
