import React from 'react';
import { History } from 'history';
import { Redirect } from 'react-router';

interface QrScanProps {
  match: { params: { id: string; name: string } };
  history: History;
}

export default (props: QrScanProps) => {
  const path = `${props.match.params.id}/${props.match.params.name}`;

  return <Redirect push to={`/viewer/${path}`} />;
};
