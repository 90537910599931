import React, { useRef, useState, useEffect } from 'react';
import styles from './SheetQR.module.scss';
import { AppState } from '../state/ducks';
import { Sheet } from '../state/ducks/sheet/types';
import { downloadCanvas } from '../utils';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import { CircularProgress, Button } from '@material-ui/core';
import { fetchQRUrl } from '../services/sheet';

const mapStateToProps = (state: AppState) => ({
  sheet: state.sheet,
});

interface SheetQRProps {
  sheet: Sheet;
  sheetId: string;
  sheetCode: string;
}

interface QRProps {
  url: string;
  filename: string;
}

const QRWithDownload = (props: QRProps) => {
  const { url, filename } = props;
  const download = () => {
    const qr = (qrRef.current
      ? qrRef.current.children[0]
      : null) as HTMLCanvasElement;
    if (qr) {
      downloadCanvas(qr, filename);
    }
  };
  const qrRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.qr}>
      <p className={styles.label}>{url.slice(url.lastIndexOf('/') + 1)}</p>
      <div ref={qrRef} className={styles.qr_container}>
        <QRCode value={url} size={256} />
      </div>
      <Button variant="outlined" color="primary" onClick={download}>
        Download
      </Button>
    </div>
  );
};

export default connect(mapStateToProps)((props: SheetQRProps) => {
  const { sheetId, sheetCode } = props;
  const [onFetch, setOnFetch] = useState(true);
  const [urls, setUrls] = useState<string[]>([]);

  useEffect(() => {
    setOnFetch(true);
    fetchQRUrl(sheetId)
      .then(({ data }) => {
        const answerKeyUrls = data.answerKeyUrls;
        setUrls(
          answerKeyUrls
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map(e => e.url)
        );
        setOnFetch(false);
      })
      .catch(err => {
        alert('Error,try again.');
        setOnFetch(false);
      });
  }, [sheetId]);

  return (
    <div className={styles.container}>
      {onFetch ? (
        <div className={styles.spinner}>
          <CircularProgress />
        </div>
      ) : (
        urls.map((url, index) => {
          return (
            <QRWithDownload
              key={sheetId + index}
              url={url}
              filename={`${sheetCode}${url.slice(
                url.lastIndexOf('/') + 1
              )}.png`}
            />
          );
        })
      )}
    </div>
  );
});
