import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import MigrateBranchSheetDialog from '../components/branch/MigrateBranchSheetDialog';
import './MigrateBranchSheet.scss';

const lessonsQuery = gql`
  {
    divisions {
      code
      levels {
        code
        lessons {
          order
          title
        }
        checkups {
          order
          title
        }
        tests {
          order
          title
        }
      }
    }
  }
`;

export default () => {
  const { loading, error, data } = useQuery(lessonsQuery);
  return (
    <div style={{ backgroundColor: 'white' }}>
      {loading ? (
        'Loading'
      ) : error ? (
        'Error'
      ) : (
        <MigrateBranchSheetDialog {...data} />
      )}
    </div>
  );
};
