import React, { useEffect, useState } from 'react';
import { SheetInfo } from '../state/ducks/sheet/types';
import styles from './CustomSheetPrinting.module.scss';
import MainContainer from '../components/MainContainer';
import returnIcon from '../assets/icons/sharp-keyboard_return-24px.svg';
import { History } from 'history';
import vdoBtn from '../assets/icons/vdo-btn.svg';
import vdoSheetBtn from '../assets/icons/vdosheet-btn.svg';
import sheetBtn from '../assets/icons/sheet-btn.svg';
import { fetchSheet, fetchFileById, fetchFile } from '../services/sheet';
import { addVideoToStudent, printSheet } from '../services/student';
import { CircularProgress } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/database';

interface CustomSheetPrintingProps {
  match: { params: { studentId: string } };
  history: History;
}

let firebaseRef: firebase.database.Reference;

export default (props: CustomSheetPrintingProps) => {
  const studentId = props.match.params.studentId;
  const { history } = props;
  const sheetReg = /([PCMEBS][KJHNUE])-(\w{1,3})(\w{1})(\d{2})([T]?)\((\d+)\.\d+\.\d+\)/;
  const [filterSheet, setFilterSheet] = useState<SheetInfo[]>([]);
  const [selectSubj, setSelectSubj] = useState('');
  const [selectList, setSelectList] = useState('');
  const [selectLevel, setSelectLevel] = useState('');
  const [selectRev, setSelectRev] = useState('0');
  const [selectNum, setSelectNum] = useState('00');
  const [isTest, setIsTest] = useState(false);
  const [printType, setPrintType] = useState('');
  const [onPrint, setOnPrint] = useState(false);
  const [loadingText, setLoadingText] = useState('Fetching');

  const goBack = () => {
    history.push('/sheetprinting');
  };
  const handleSubj = (subj: string) => {
    if (selectSubj === subj) {
      setSelectSubj('');
    } else {
      setSelectSubj(subj);
    }
  };
  const handleList = (list: string) => {
    if (selectList === list) {
      setSelectList('');
    } else {
      setSelectList(list);
    }
  };
  const handleLevel = (level: string) => {
    if (selectLevel === level) {
      setSelectLevel('');
    } else {
      setSelectLevel(level);
    }
  };
  const handleRev = (rev: string) => {
    if (selectRev === rev) {
      setSelectRev('');
    } else {
      setSelectRev(rev);
    }
  };
  const handleNum = (num: string) => {
    setSelectNum(`${selectNum.slice(-1)}${num}`);
  };
  const delNum = () => {
    setSelectNum(`0${selectNum.slice(0, 1)}`);
  };
  const uniqSubj = () => {
    const subj = [];
    for (const i of filterSheet) {
      const exec = sheetReg.exec(i.code);
      if (exec && subj.indexOf(exec[1]) < 0) {
        subj.push(exec[1]);
      }
    }
    return subj.sort().map((el, index) => {
      return (
        <div
          key={`subj_${index}`}
          className={`${styles.subjBox} ${el === selectSubj ? styles[el] : ''}`}
          onClick={() => handleSubj(el)}
        >
          {el}
        </div>
      );
    });
  };
  const uniqList = () => {
    const list = [];
    for (const i of filterSheet) {
      const exec = sheetReg.exec(i.code);
      if (exec && exec[1] === selectSubj && list.indexOf(exec[2]) < 0) {
        list.push(exec[2]);
      }
    }
    return list.sort().map((el, index) => {
      return (
        <div
          key={`list_${index}`}
          className={`${styles.listBox} ${
            el === selectList ? styles.active : ''
          }`}
          onClick={() => handleList(el)}
        >
          {el}
        </div>
      );
    });
  };
  const uniqLevel = () => {
    const level = [];
    for (const i of filterSheet) {
      const exec = sheetReg.exec(i.code);
      if (
        exec &&
        exec[1] === selectSubj &&
        exec[2] === selectList &&
        level.indexOf(exec[3]) < 0
      ) {
        level.push(exec[3]);
      }
    }
    return level.sort().map((el, index) => {
      return (
        <div
          key={`level_${index}`}
          className={`${styles.levelBox} ${
            el === selectLevel ? styles.activeLevel : ''
          }`}
          onClick={() => handleLevel(el)}
        >
          {el}
        </div>
      );
    });
  };
  const uniqRev = () => {
    const rev = [];
    for (const i of filterSheet) {
      const exec = sheetReg.exec(i.code);
      if (
        exec &&
        exec[1] === selectSubj &&
        exec[2] === selectList &&
        exec[3] === selectLevel &&
        rev.indexOf(exec[6]) < 0
      ) {
        rev.push(exec[6]);
      }
    }
    const sorted = rev.sort();
    return sorted.map((el, index) => {
      return (
        <div
          key={`rev_${index}`}
          className={`${styles.revBox} ${
            el === selectRev ? styles.active : ''
          }`}
          onClick={() => handleRev(el)}
        >
          r{el}
        </div>
      );
    });
  };
  const handlePrintType = (type: string) => {
    if (type === printType) {
      setPrintType('');
    } else {
      setPrintType(type);
    }
  };
  const handleCanPrint = () => {
    const code = `${selectSubj}-${selectList}${selectLevel}${selectNum}${
      isTest ? 'T' : ''
    }(${selectRev}`;
    for (const i of filterSheet) {
      if (i.code.indexOf(code) >= 0) {
        return i._id;
      }
    }
    return undefined;
  };
  const handlePrint = async (type: string) => {
    const foundId = handleCanPrint();
    if (foundId) {
      try {
        setOnPrint(true);
        const data = (await fetchFileById(foundId)).data;
        if (type === '' || type === 'vdo') {
          setLoadingText('Add student history');
          try {
            await addVideoToStudent(studentId.slice(0, -1), foundId);
            firebaseRef = firebase.database().ref('/live/printing');
            if (firebaseRef) {
              firebaseRef.update({
                [`${studentId.slice(0, -1)}`]: foundId,
              });
            }
          } catch (error) {
            // empty
          }
        }
        if (type === '' || type === 'print') {
          setLoadingText('Printing');
          try {
            const lct = [];
            const exm = [];
            const exr = [];
            const rvw = [];
            for (const i of data.fileNames) {
              if (printType === 'LCT' || printType === '') {
                if (i.indexOf('lecturePdf1') >= 0) {
                  lct.push(i);
                }
              }
              if (printType === 'EXM' || printType === '') {
                if (i.indexOf('examplePdf1') >= 0) {
                  exm.push(i);
                }
              }
              if (printType === 'EXR' || printType === '') {
                if (i.indexOf('exercisePdf1') >= 0) {
                  exr.push(i);
                }
              }
              if (printType === '') {
                if (i.indexOf('checkpointPdf1') >= 0) {
                  rvw.push(i);
                }
              }
              if (printType === 'RVW') {
                if (i.indexOf('reviewPdf1') >= 0) {
                  rvw.push(i);
                }
              }
            }
            const files = lct.concat(exm, exr, rvw);
            const promise = [];
            for (const i of files) {
              promise.push(fetchFile(i));
            }
            const signUrl = (await Promise.all(promise)).map(
              e => e.data.signedUrl
            );
            await printSheet(signUrl);
          } catch (error) {
            // empty
          }
        }
        goBack();
      } catch (error) {
        setOnPrint(false);
      }
    } else {
      alert('Can not find this sheet.');
    }
  };

  useEffect(() => {
    fetchSheet()
      .then(({ data }) => {
        let thisSubj = 'M';
        switch (studentId.slice(-1)) {
          case '2':
            thisSubj = 'P';
            break;
          case '3':
            thisSubj = 'C';
            break;
          case '4':
            thisSubj = 'E';
            break;
          default:
            break;
        }
        setFilterSheet(
          data.filter(e => e.code.slice(0, 1).toUpperCase() === thisSubj)
        );
      })
      .catch(err => {
        console.log('can not fetchSheet');
      });
  }, [studentId]);

  return (
    <MainContainer>
      {onPrint ? (
        <div className={styles.overlayBG}>
          <div className={styles.dialog}>
            <CircularProgress size={130} color={'secondary'} thickness={6} />
            <p className={styles.loadingText}>{loadingText}</p>
          </div>
        </div>
      ) : null}
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.label}>
            {selectSubj}-{selectList}
            {selectLevel}
            {selectNum}
            {isTest ? 'T' : ''}(R{selectRev})
          </p>
          <img
            src={returnIcon}
            alt="returnBtn"
            className={styles.backBtn}
            onClick={() => history.goBack()}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.subjCol}>{uniqSubj()}</div>
          <div className={styles.listCol}>{uniqList()}</div>
          <div className={styles.levelCol}>
            <div className={styles.levelRow}>{uniqLevel()}</div>
            <div className={styles.printRow}>
              <div
                className={`${styles.printBox} ${
                  printType === 'LCT' ? styles.activePrint : ''
                }`}
                onClick={() => handlePrintType('LCT')}
              >
                LCT
              </div>
              <div
                className={`${styles.printBox} ${
                  printType === 'EXM' ? styles.activePrint : ''
                }`}
                onClick={() => handlePrintType('EXM')}
              >
                EXM
              </div>
              <div
                className={`${styles.printBox} ${
                  printType === 'EXR' ? styles.activePrint : ''
                }`}
                onClick={() => handlePrintType('EXR')}
              >
                EXR
              </div>
              <div
                className={`${styles.printBox} ${
                  printType === 'RVW' ? styles.activePrint : ''
                }`}
                onClick={() => handlePrintType('RVW')}
              >
                RVW
              </div>
            </div>
          </div>
          <div className={styles.numCol}>
            <div className={styles.numRow}>
              <div className={styles.numBox} onClick={() => handleNum('7')}>
                7
              </div>
              <div className={styles.numBox} onClick={() => handleNum('8')}>
                8
              </div>
              <div className={styles.numBox} onClick={() => handleNum('9')}>
                9
              </div>
              <div className={styles.numBox} onClick={() => handleNum('4')}>
                4
              </div>
              <div className={styles.numBox} onClick={() => handleNum('5')}>
                5
              </div>
              <div className={styles.numBox} onClick={() => handleNum('6')}>
                6
              </div>
              <div className={styles.numBox} onClick={() => handleNum('1')}>
                1
              </div>
              <div className={styles.numBox} onClick={() => handleNum('2')}>
                2
              </div>
              <div className={styles.numBox} onClick={() => handleNum('3')}>
                3
              </div>
              <div className={styles.delBox} onClick={delNum}>
                C
              </div>
              <div className={styles.numBox} onClick={() => handleNum('0')}>
                0
              </div>
              <div className={styles.delBox} onClick={() => setIsTest(!isTest)}>
                T
              </div>
            </div>
            <div className={styles.revRow}>{uniqRev()}</div>
            <div className={styles.btnRow}>
              <img
                src={sheetBtn}
                alt="print"
                style={{ cursor: 'pointer' }}
                onClick={() => handlePrint('print')}
              />
              <img
                src={vdoBtn}
                alt="vdo"
                style={{ cursor: 'pointer' }}
                onClick={() => handlePrint('vdo')}
              />
              <img
                src={vdoSheetBtn}
                alt="printvdo"
                style={{ cursor: 'pointer' }}
                onClick={() => handlePrint('')}
              />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
