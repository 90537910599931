import React from 'react';
import styles from './NotFound.module.scss';
import logo from '../assets/images/notfound.png';
import { History } from 'history';

interface NotFoundProps {
  history: History;
}

export default (props: NotFoundProps) => {
  const musicSrc =
    'https://mp3oreo.com/storage/603eae36d68b4d431dca9648c28f6f06.mp3?k=eyJpdiI6Ik5laGNLbndvNTNybEQ3SzZNSzhBT2c9PSIsInZhbHVlIjoiblQrWVB3bVRKNHorS3J1OUR5V0dzT3dFdDBmb3F2RmNVNjJPRGxydXhmVFU4VFp6M1haSmFIckpweE83OVM4Y3R4MEMxNStrS0JTZk5xQWJmTXZ4U0pVcTJrNU50UG5xa3VVZGo3V1B4NExiNnVBWDFpcExBTzdWY3BlMmZVWUFRQ0N2aUkxeGJaMmFhc3dPbStwN0Z3PT0iLCJtYWMiOiJlZDgwMGY3ODM5MmE0N2MzN2RmZWE2NWM0ZDMzNzhmYzg0Y2YxOTFhMzA4ODYxZjE2NTQ3YWNkN2NkNTI4ZjM3In0=&d=blackpink-kill-this-love.mp3';
  const goToSheetList = () => {
    props.history.push('/sheet');
  };

  return (
    <div className={styles.background}>
      <div className={styles.title} onClick={() => goToSheetList()}>
        404
      </div>
      <img
        className={styles.img}
        src={logo}
        alt="notfound-logo"
        onClick={() => goToSheetList()}
      ></img>
      <audio autoPlay loop preload="none">
        <source src={musicSrc} type="audio/mp3"></source>
      </audio>
    </div>
  );
};
