import React, { useEffect } from 'react';
import { History } from 'history';
import MainContainer from '../components/MainContainer';
import axios from 'axios';
import { login } from '../services/authentication';
import jwt from 'jwt-decode';

interface RedirectProps {
  history: History;
  location: { search: string };
}

export default (props: RedirectProps) => {
  const ticket = props.location.search;

  useEffect(() => {
    isLogin();
  }, []);

  const isLogin = async () => {
    const token = window.sessionStorage.getItem('token');
    if (token) {
      window.location.replace('/');
    } else if (ticket !== '') {
      const data = ticket.slice(ticket.indexOf('=') + 1);
      const res = (await axios.get<string>(
        `${process.env.REACT_APP_AUTH_URL}/auth/token?ticket=${data}`
      )).data;
      const decode = jwt<{ authId: string; exp: number; username: string }>(
        res
      );
      window.sessionStorage.setItem('token', res);
      window.sessionStorage.setItem('authId', decode.authId);
      window.sessionStorage.setItem('exp', decode.exp + '');
      window.sessionStorage.setItem('username', decode.username);
      window.location.replace('/');
    } else {
      login();
    }
  };

  return (
    <MainContainer>
      <div style={{ color: 'white', margin: '12px', fontSize: '2em' }}>
        Authorization...
      </div>
    </MainContainer>
  );
};
