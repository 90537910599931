import axios from 'axios';
import {
  SheetInfo,
  SheetInfoType,
  Subject,
  SheetClass,
  SheetQR,
  SheetEdit,
  SheetStatus,
  SheetMaterials,
} from '../state/ducks/sheet/types';

export const headers = {
  authorization: `Bearer ${window.sessionStorage.getItem('token') || '1111'}`,
};

/**
 * Get all sheets
 */
export const fetchSheet = () => {
  return axios.get<SheetInfo[]>(`${process.env.REACT_APP_BASE_URL}/sheet`, {
    headers,
  });
};

/**
 * Get sheet info by sheetId
 * @param sheetId
 */
export const fetchSheetById = (sheetId: string) => {
  return axios.get<SheetInfo>(
    `${process.env.REACT_APP_BASE_URL}/sheet/${sheetId}`,
    { headers }
  );
};

/**
 * Get filepaths by sheetId
 * @param sheetId
 */
export const fetchFileById = (sheetId: string) => {
  return axios.get<{ fileNames: string[] }>(
    `${process.env.REACT_APP_BASE_URL}/sheet/file/${sheetId}`,
    { headers }
  );
};

/**
 * Get files from storage
 * @param path
 */
export const fetchFile = (path: string) => {
  return axios.get<{ contentType: string; signedUrl: string }>(
    `${process.env.REACT_APP_BASE_URL}/sheet/file/${path}`,
    { headers }
  );
};

/**
 * Get QR code urls
 * @param sheetId
 */
export const fetchQRUrl = (sheetId: string) => {
  return axios.get<SheetQR>(
    `${process.env.REACT_APP_BASE_URL}/sheet/qr/${sheetId}`,
    { headers }
  );
};

/**
 * Create new sheet
 * @param code
 * @param title
 * @param subject
 * @param sheetClass
 * @param order
 * @param type
 */
export const createSheet = (
  code: string,
  title: string,
  subject: Subject,
  sheetClass: SheetClass,
  order: Number,
  type: SheetInfoType
) => {
  return axios.post<SheetInfo>(
    `${process.env.REACT_APP_BASE_URL}/sheet`,
    {
      code,
      title,
      subject,
      class: sheetClass,
      order,
      type,
    },
    { headers }
  );
};

/**
 * Edit sheet info
 * @param sheetId
 * @param body
 */
export const editSheetInfo = (sheetId: string, body: Partial<SheetEdit>) => {
  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/sheet/${sheetId}`,
    body,
    { headers }
  );
};

/**
 * Edit sheet status
 * @param sheetId
 * @param status
 */
export const editSheetStatus = (sheetId: string, status: SheetStatus) => {
  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/sheet/${sheetId}/status`,
    { status },
    { headers }
  );
};

/**
 * Edit sheet material
 * @param sheetId
 * @param materials
 */
export const editSheetMaterials = (
  sheetId: string,
  materials: Partial<SheetMaterials>
) => {
  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/sheet/${sheetId}/materials`,
    materials,
    { headers }
  );
};

/**
 * Upload sheet to storage
 * @param sheetId
 * @param data
 * @param progress
 */
export const uploadSheet = (
  sheetId: string,
  data: FormData,
  progress?: any
) => {
  let config;
  if (progress) {
    config = {
      headers: { 'content-type': 'multipart/form-data', ...headers },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        progress(progressEvent);
      },
    };
  } else {
    config = {
      headers: { 'content-type': 'multipart/form-data', ...headers },
    };
  }
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/sheet/upload/${sheetId}`,
    data,
    config
  );
};

/**
 * Delete sheet
 * @param sheetId
 */
export const deleteSheet = (sheetId: string) => {
  return axios.delete(`${process.env.REACT_APP_BASE_URL}/sheet/${sheetId}`, {
    headers,
  });
};

interface Res {
  subject: Subject;
  sheetClass: SheetClass;
  order: number;
  sheetType: SheetInfoType;
  isCorrect: boolean;
}

/**
 * Extract sheet code component
 * @param value
 */
export const extractCode = (value: string) => {
  const data = value.toUpperCase();
  const res: Res = {
    subject: Subject.MATH,
    sheetClass: SheetClass.KIDS,
    sheetType: SheetInfoType.SHEET,
    order: 0,
    isCorrect: false,
  };
  const sheetReg = /([PCMEBS])([KJHNUE])-\w{1,4}(\d{2})([T]?)\(\d+\.\d+\.\d+\)/;
  if (data !== '' && sheetReg.test(data)) {
    const sheetArr = sheetReg.exec(data) || [];
    switch (sheetArr[1] || '') {
      case 'P':
        res.subject = Subject.PHYSICS;
        break;
      case 'S':
      case 'M':
        res.subject = Subject.MATH;
        break;
      case 'C':
        res.subject = Subject.CHEMISTRY;
        break;
      case 'E':
        res.subject = Subject.ENGLISH;
        break;
      case 'B':
        res.subject = Subject.BIOLOGY;
        break;
      default:
        break;
    }
    switch (sheetArr[2] || '') {
      case 'K':
        res.sheetClass = SheetClass.KIDS;
        break;
      case 'J':
        res.sheetClass = SheetClass.JUNIOR;
        break;
      case 'H':
        res.sheetClass = SheetClass.HIGH;
        break;
      case 'N':
        res.sheetClass = SheetClass.NURSERY;
        break;
      case 'U':
        res.sheetClass = SheetClass.UNIVERSITY;
        break;
      case 'E':
        res.sheetClass = SheetClass.EVERYDAY;
        break;
      default:
        break;
    }
    if (sheetArr[4] !== '') {
      res.sheetType = SheetInfoType.TEST;
    }
    if (sheetArr[3]) {
      res.order = Number(sheetArr[3]);
    }
    res.isCorrect = true;
  }
  return res;
};
