import React, { useState } from 'react';
import MainContainer from '../components/MainContainer';
import { History } from 'history';
import Footer from '../assets/images/footer.svg';
import LogoYellow from '../assets/images/logo-yellow.svg';
import styles from './SheetPrinting.module.scss';

type SheetPrintingProps = {
  history: History;
};

export default (props: SheetPrintingProps) => {
  const { history } = props;
  const [studentId, setStudentId] = useState('');

  const submit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const regId = /\d{6}/;
    if (regId.test(studentId)) {
      history.push(`sheetprinting/${studentId}`);
    } else {
      setStudentId('WRONG');
      setTimeout(() => {
        setStudentId('');
      }, 1000);
    }
  };

  return (
    <MainContainer>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.mainGrid}>
            <object
              type="image/svg+xml"
              data={LogoYellow}
              title="Yellow_Logo"
            />
            <div className={styles.inputBox}>
              <p className={styles.label}>STUDENT'S ID</p>
              <form onSubmit={e => submit(e)} style={{ width: 'auto' }}>
                <input
                  autoFocus
                  type="text"
                  maxLength={6}
                  className={styles.input}
                  placeholder="888888"
                  value={studentId}
                  onChange={e => setStudentId(e.currentTarget.value)}
                />
              </form>
            </div>
          </div>
        </div>
        <object
          type="image/svg+xml"
          data={Footer}
          className={styles.footer}
          title="Footer_line"
        />
      </div>
    </MainContainer>
  );
};
