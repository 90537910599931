export enum Subject {
  PHYSICS = 'PHYSICS',
  CHEMISTRY = 'CHEMISTRY',
  MATH = 'MATH',
  ENGLISH = 'ENGLISH',
  BIOLOGY = 'BIOLOGY',
}

export enum SheetClass {
  NURSERY = 'NURSERY',
  KIDS = 'KIDS',
  JUNIOR = 'JUNIOR',
  HIGH = 'HIGH',
  UNIVERSITY = 'UNIVERSITY',
  EVERYDAY = 'EVERYDAY',
}

export enum SheetInfoType {
  TEST = 'TEST',
  SHEET = 'SHEET',
}

export enum SheetStatus {
  WIP = 'WIP',
  Submitted = 'Submitted',
  PreChecked = 'PreChecked',
  PostChecked = 'PostChecked',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface SheetInfo {
  _id: string;
  code: string;
  title: string;
  subject: Subject;
  class: SheetClass;
  order: number;
  type: SheetInfoType;
  status: SheetStatus;
  videoName: string;
  reviewNames: string[];
  lectureNames: string[];
  exercises: Array<{ name: string; order: number }>;
  answerKeys: Array<{ name: string; order: number }>;
  __v: number;
}

export interface SheetEdit {
  code: string;
  title: string;
  subject: string;
  class: string;
  order: number;
  type: string;
}

export interface SheetMaterials {
  videoName: string;
  reviewNames: string[];
  lectureNames: string[];
  exercises: {
    order: number;
    name: string;
  }[];
  answerKeys: {
    order: number;
    name: string;
  }[];
}

export interface SheetExerciseUrls {
  order: number;
  url: string;
}

export interface SheetAnswerKeyUrls {
  order: number;
  url: string;
}

export interface SheetQR {
  videoUrl: string;
  reviewUrls: string[];
  lectureUrls: string[];
  exerciseUrls: SheetExerciseUrls[];
  answerKeyUrls: SheetAnswerKeyUrls[];
}

export interface Sheet {
  onFetch: boolean;
  info: SheetInfo[];
}

export enum SheetType {
  FETCH,
  INFO,
}

export interface SheetAction {
  type: SheetType;
  payload: Partial<Sheet>;
}
