import React from 'react';
import styles from './ErrorText.module.scss';

export default () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Error :(</h1>
      <p>We're sorry, but we can't fetch data from server.</p>
      <p>Please check your network and try again.</p>
    </div>
  );
};
