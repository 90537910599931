import axios from 'axios';
import { headers } from './sheet';

export interface SheetInPlan {
  _id: string;
  code: string;
}

export interface Plan {
  _id: string;
  name: string;
  sheetIds: SheetInPlan[];
}

export interface PlanWithOutCode {
  _id: string;
  name: string;
  sheetIds: string[];
}

export interface PlanList {
  plans: PlanWithOutCode[];
}

export interface UpdatePlan {
  name?: string;
  sheetIds?: string[];
}

/**
 * Get all plans
 */
export const getPlan = () => {
  return axios.get<PlanList>(`${process.env.REACT_APP_BASE_URL}/plan`, { headers });
}

/**
 * Get plan by planId
 * @param id 
 */
export const getPlanById = (id: string) => {
  return axios.get<Plan>(`${process.env.REACT_APP_BASE_URL}/plan/${id}`, { headers });
}

/**
 * Create new plan
 * @param name 
 */
export const createNewPlan = (name: string) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/plan`, { name }, { headers });
}

/**
 * Update plan info and sequence
 * @param id 
 * @param data 
 */
export const updatePlan = (id: string, data: UpdatePlan) => {
  return axios.patch(`${process.env.REACT_APP_BASE_URL}/plan/${id}`, data, { headers });
}

/**
 * Delete plan
 * @param id 
 */
export const deletePlan = (id: string) => {
  return axios.delete(`${process.env.REACT_APP_BASE_URL}/plan/${id}`, { headers });
}
