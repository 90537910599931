import React, { useState, useEffect } from 'react';
import MainContainer from '../components/MainContainer';
import { fetchFile, fetchFileById } from '../services/sheet';
import PdfViewer from '../components/PdfViewer';
import { History } from 'history';
import { Button, LinearProgress } from '@material-ui/core';
import styles from './Viewer.module.scss';

type ViewerProps = {
  match: { params: { sheetId: string; sheetName: string } };
  history: History;
};

export default (props: ViewerProps) => {
  const { sheetId, sheetName } = props.match.params;
  const [fileType, setFileType] = useState('');
  const [fileUrl, setFileUrl] = useState<string[]>([]);
  const [isFullView, setIsFullView] = useState(false);

  const getFile = async () => {
    const files: string[] = [];
    if (sheetName === 'fullsheet') {
      try {
        const res = await fetchFileById(sheetId);
        const lecture = [];
        const example = [];
        const exercise = [];
        const checkpoint = [];
        for (const i of res.data.fileNames) {
          const fileName = i.slice(i.indexOf('/') + 1);
          if (fileName.indexOf('lecturePdf') === 0) {
            lecture.push(i);
          } else if (fileName.indexOf('examplePdf') === 0) {
            example.push(i);
          } else if (fileName.indexOf('exercisePdf') === 0) {
            exercise.push(i);
          } else if (fileName.indexOf('checkpointPdf') === 0) {
            checkpoint.push(i);
          }
        }
        lecture.sort();
        example.sort();
        exercise.sort();
        checkpoint.sort();
        const fullDoc = [...lecture, ...example, ...exercise, ...checkpoint];
        for (const i of fullDoc) {
          const url = await fetchFile(i);
          files.push(url.data.signedUrl);
        }
        setFileType('pdf');
        setFileUrl(files);
      } catch (error) {
        // empty
      }
    } else {
      try {
        const url = await fetchFile(`${sheetId}/${sheetName}`);
        const fileType = url.data.contentType;
        setFileType(fileType);
        files.push(url.data.signedUrl);
        setFileUrl(files);
      } catch (error) {
        // empty
      }
    }
  };
  const goBack = () => {
    props.history.goBack();
  };
  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = fileUrl[0];
    link.setAttribute('target', '_blank');
    link.click();
  };
  const renderViewer = () => {
    if (fileType === '') {
      return <LinearProgress color="secondary" />;
    } else if (fileType.indexOf('pdf') >= 0) {
      return (
        <>
          <Button
            variant="contained"
            color="secondary"
            className={styles.back_btn}
            onClick={() => goBack()}
          >
            Back
          </Button>
          <div className={styles.changeview_btn}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => downloadFile()}
            >
              DOWNLOAD
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsFullView(!isFullView)}
            >
              Change View
            </Button>
          </div>
          {fileUrl.map((e, i) => (
            <PdfViewer key={i} url={e} fullView={isFullView} />
          ))}
        </>
      );
    } else if (fileType.indexOf('video') >= 0) {
      return (
        <div className={styles.center_grid}>
          <video
            controls
            controlsList="nodownload"
            className={styles.video_viewer}
          >
            <source type="video/mp4" src={fileUrl[0]}></source>
          </video>
          <Button variant="outlined" color="secondary" onClick={() => goBack()}>
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div className={styles.center_grid}>
          <h1 className={styles.pink}>{sheetName}</h1>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => goBack()}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => downloadFile()}
            >
              DOWNLOAD
            </Button>
          </div>
        </div>
      );
    }
  };

  const pageScroll = (ev: KeyboardEvent) => {
    if (ev.keyCode === 37 || ev.keyCode === 39) {
      ev.preventDefault();
      const windowW = window.innerWidth;
      const scrollLength = Math.floor(
        (windowW < 1000 ? windowW : 1000) * Math.sqrt(2) + 6
      );
      const html = document.documentElement;
      const body = document.body;
      if (ev.keyCode === 37) {
        html.scrollTop -= scrollLength;
        body.scrollTop -= scrollLength;
      } else {
        html.scrollTop += scrollLength;
        body.scrollTop += scrollLength;
      }
    }
  };

  useEffect(() => {
    getFile();
  }, [sheetId, sheetName]);
  useEffect(() => {
    document.addEventListener('keydown', pageScroll);
    return () => {
      document.removeEventListener('keydown', pageScroll);
    };
  }, []);

  return <MainContainer>{renderViewer()}</MainContainer>;
};
