import React, { useEffect, useState } from 'react';
import styles from './PdfViewer.module.scss';
import {
  GlobalWorkerOptions,
  version,
  getDocument,
  PDFDocumentProxy,
} from 'pdfjs-dist';
import PdfPage from './PdfPage';
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;

interface PdfViewerProps {
  url: string;
  fullView?: boolean;
}

export default (props: PdfViewerProps) => {
  const { url, fullView } = props;
  const [pages, setPages] = useState<
    Array<{ pdf: PDFDocumentProxy; page: number }>
  >([]);
  const [numPage, setNumPage] = useState<number>(1);

  const getDocuments = async () => {
    try {
      const pdf: PDFDocumentProxy = await getDocument(url).promise;
      const numPages = pdf.numPages;
      setNumPage(numPages);
      const pages = [];
      for (let i = 0; i < numPages; i++) {
        pages.push({ pdf, page: i + 1 });
      }
      setPages(pages);
    } catch (error) {
      // empty
    }
  };

  useEffect(() => {
    getDocuments();
  }, [url]);

  return fullView ? (
    <div className={styles.pdf_container_full}>
      {pages.length > 0
        ? pages.map((e, i) => (
            <PdfPage fullView={true} key={i} pdf={e.pdf} page={e.page} />
          ))
        : null}
    </div>
  ) : (
    <div className={styles.pdf_container}>
      {pages.length > 0
        ? pages.map((e, i) => <PdfPage key={i} pdf={e.pdf} page={e.page} />)
        : null}
      {numPage % 2 === 0 ? null : <div className={styles.blank} />}
    </div>
  );
};
