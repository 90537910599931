const subjOrder = ['mn', 'mk', 'mj', 'mh', 'pj', 'ph', 'cj', 'ch', 'mu', 'pu'];
const mathOrder = [
  'mk-ab', 'mk-bb', 'mk-db', 'mk-cb', 'mk-eb', 'mk-fb', 'mk-xgb', 'mk-xssb', 'mk-xpsb',
  'mk-ai', 'mk-bi', 'mk-ci', 'mk-di', 'mk-xgi',
  'mj-bb', 'mj-cb', 'mj-db', 'mj-eb', 'mj-fb', 'mj-gb', 'mj-xtb', 'mj-xgb', 'mj-xssb', 'mj-xstb', 'mj-xpb',
  'mj-bi', 'mj-di', 'mj-ei', 'mj-fi', 'mj-ci', 'mj-gi', 'mj-xssi', 'mj-xsti', 'mj-xpi', 'mj-xti', 'mj-xgi',
  'mh-bb', 'mh-cb', 'mh-db', 'mh-xsb', 'mh-xlb', 'mh-xrb', 'mh-xmb', 'mh-xnb', 'mh-xcnb', 'mh-pxfb',
  'mh-xfb', 'mh-xelb', 'mh-pxtb', 'mh-xtb', 'mh-xvb', 'mh-xcb', 'mh-xcab', 'mh-xssb', 'mh-xpb', 'mh-xstb',
  'mh-xsti', 'mh-bi', 'mh-ci', 'mh-di', 'mh-xsi', 'mh-xli', 'mh-xeli', 'mh-xcni', 'mh-xcai', 'mh-xfi', 'mh-xti',
  'mh-xvi', 'mh-xci', 'mh-xmi', 'mh-xni', 'mh-xssi', 'mh-xpi',
  'mu-dib', 'mu-inb', 'mu-mvcb', 'mu-deb', 'mu-vcb', 'mu-lab', 'mu-pdeb'
];
const phyOrder = [
  'pj-ppb', 'pj-prb', 'pj-pvb', 'pj-peb', 'pj-pub', 'pj-mob', 'pj-mnb', 'pj-meb', 'pj-mpb', 'pj-mwb',
  'pj-mmb', 'pj-mcb', 'pj-edb', 'pj-wlb', 'pj-slb',
  'pj-moi', 'pj-mni', 'pj-mei', 'pj-mpi', 'pj-mwi', 'pj-mmi', 'pj-mci', 'pj-edi', 'pj-sti', 'pj-wli', 'pj-sli',
  'ph-pmb', 'ph-mob', 'ph-mnb', 'ph-meb', 'ph-mwb', 'ph-mpb', 'ph-mmb', 'ph-mcb', 'ph-mrb', 'ph-msb', 'ph-wgb', 'ph-wwb', 'ph-wlb',
  'ph-wsb', 'ph-ssb', 'ph-slb', 'ph-sgb', 'ph-stb', 'ph-esb', 'ph-emb', 'ph-edb', 'ph-eab', 'ph-qab', 'ph-qnb',
  'ph-pmi', 'ph-moi', 'ph-mni', 'ph-mei', 'ph-mwi', 'ph-mpi', 'ph-mmi', 'ph-mci', 'ph-mri', 'ph-msi', 'ph-ssi', 'ph-sli', 'ph-sti',
  'ph-sgi', 'ph-edi', 'ph-esi', 'ph-emi', 'ph-eai', 'ph-wwi', 'ph-wsi', 'ph-wli', 'ph-qai', 'ph-qni'
];
const chemOrder = [
  'ch-bb', 'ch-mbb', 'ch-db', 'ch-gb', 'ch-hb', 'ch-ib', 'ch-rlb', 'ch-xob'
]

export const getSubjOrder = (subj: string) => {
  const index = subjOrder.indexOf(subj.toLowerCase());
  if (index >= 0) {
    return index;
  } else {
    return 999;
  }
}

export const getSheetOrder = (name: string) => {
  let index = 999;
  switch (name.slice(0, 1).toLowerCase()) {
    case 'm':
      index = mathOrder.indexOf(name.slice(0, -3).toLowerCase());
      break;
    case 'p':
      index = phyOrder.indexOf(name.slice(0, -3).toLowerCase());
      break;
    case 'c':
      index = chemOrder.indexOf(name.slice(0, -3).toLowerCase());
      break;
    default:
      break;
  }
  if (index >= 0) {
    return index;
  } else {
    return 999;
  }
}