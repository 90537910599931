import React, { useEffect, useState } from 'react';
import MainContainer from '../components/MainContainer';
import styles from './Sheet.module.scss';
import { connect } from 'react-redux';
import { getSheets } from '../state/ducks/sheet/operations';
import SheetList from '../components/SheetList';
import { Fab, LinearProgress, Menu, MenuItem, Fade } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddSheetDialog from '../components/AddSheetDialog';
import { AppState } from '../state/ducks';
import {
  Sheet,
  SheetInfoType,
  SheetInfo,
  SheetStatus,
} from '../state/ducks/sheet/types';
import { History } from 'history';
import EditSheetDialog from '../components/EditSheetDialog';
import { editSheetStatus } from '../services/sheet';
import { getSubjOrder, getSheetOrder } from '../utils/sortOrder';
import * as ls from 'local-storage';

const mapStateToProps = (state: AppState) => ({
  sheet: state.sheet,
});

const mapDispatchToProps = { getSheets };

interface SheetsProps {
  sheet: Sheet;
  getSheets: () => Promise<void>;
  match: {
    params: {
      subj: string;
      expanded: string;
    };
  };
  history: History;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: SheetsProps) => {
  const { sheet, getSheets } = props;
  const selectedSubj = props.match.params.subj;
  const [activeLevel, setActiveLevel] = useState('b');
  const [isOpenDialog, toggleDialog] = useState(false);
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [selectedSheet, setSelectedSheet] = useState<SheetInfo | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const [expandedPanel, setExpandedPanel] = useState('');

  const gotoSheetInfo = (sheetId: string) => {
    props.history.push(`/sheetlist/info/${sheetId}`);
  };

  const editSheets = (sheetInfo: SheetInfo) => {
    setSelectedSheet(sheetInfo);
    setIsOnEdit(true);
  };

  const openMenu = (event: any, inputId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(inputId);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const changeSheetStatus = (status: SheetStatus) => {
    editSheetStatus(selectedId, status)
      .then(() => {
        getSheets();
        closeMenu();
      })
      .catch(err => {
        closeMenu();
      });
  };

  const genNav = () => {
    const res: string[] = [];
    for (const i of sheet.info) {
      if (res.indexOf(i.code.toLowerCase().substr(0, 2)) < 0) {
        res.push(i.code.toLowerCase().substr(0, 2));
      }
    }
    const nav = res
      .sort((a, b) => {
        return getSubjOrder(a) - getSubjOrder(b);
      })
      .map((e, i) => {
        return (
          <div
            key={`subjnav_${i}`}
            className={`${styles.navlist} ${styles[e]}`}
            onClick={() => changeSubject(e)}
          >
            {e.toUpperCase()}
          </div>
        );
      });
    return nav;
  };

  const getFilteredSheet = () => {
    return sheet.info.filter(
      e => e.code.toLowerCase().substr(0, 2) === selectedSubj
    );
  };

  const genSubnav = () => {
    const uniqueSubj: string[] = [];
    const filteredSheet = getFilteredSheet();
    const sheetReg = /[PCMEBS][KJHNUE]-\w{1,3}(\w{1})\d{2}/;
    for (const i of filteredSheet) {
      const exec = sheetReg.exec(i.code);
      if (exec && exec[1]) {
        if (uniqueSubj.indexOf(exec[1].toLowerCase()) < 0) {
          uniqueSubj.push(exec[1].toLowerCase());
        }
      }
    }
    const sortedRes = uniqueSubj.sort((a, b) => {
      const order = ['b', 'i', 'a', 'e', 's'];
      return order.indexOf(a.toLowerCase()) - order.indexOf(b.toLowerCase());
    });
    return sortedRes.map((e, i) => {
      const sheetCount = filteredSheet.reduce((prev, current) => {
        const exec = sheetReg.exec(current.code);
        if (
          exec &&
          exec[1] &&
          exec[1].toLowerCase() === e &&
          current.type === SheetInfoType.SHEET
        ) {
          return (prev += 1);
        } else {
          return prev;
        }
      }, 0);
      const testCount = filteredSheet.reduce((prev, current) => {
        const exec = sheetReg.exec(current.code);
        if (
          exec &&
          exec[1] &&
          exec[1].toLowerCase() === e &&
          current.type === SheetInfoType.TEST
        ) {
          return (prev += 1);
        } else {
          return prev;
        }
      }, 0);
      return (
        <div
          key={`levelnav_${i}`}
          className={`${styles.subnavlist} ${
            e === activeLevel ? styles.active : ''
          }`}
          onClick={() => setActiveLevel(e)}
        >
          {`${e.toUpperCase()} (${sheetCount}`}+
          <span style={{ color: '#2c78bf' }}>{testCount}</span>)
        </div>
      );
    });
  };

  const genSheetList = () => {
    const listReg = /([PCMEBS][KJHNUE]-\w{1,3})(\w{1})\d{2}[T]?\((\d+)\.\d+\.\d+\)/;
    const filteredSheet = getFilteredSheet();
    const allList: string[] = [];
    for (const i of filteredSheet) {
      const exec = listReg.exec(i.code);
      if (exec && exec[1] && exec[2] && exec[3]) {
        const listName = `${exec[1]}${exec[2]} r${exec[3]}`;
        if (
          exec[2].toLowerCase() === activeLevel &&
          allList.indexOf(listName) < 0
        ) {
          allList.push(listName);
        }
      }
    }
    return allList
      .sort((a, b) => {
        return getSheetOrder(a) - getSheetOrder(b);
      })
      .map(list => {
        const sheets = filteredSheet.filter(e => {
          const exec = listReg.exec(e.code);
          if (exec && exec[1] && exec[2] && exec[3]) {
            const sheetName = `${exec[1]}${exec[2]} r${exec[3]}`;
            return sheetName === list;
          } else {
            return false;
          }
        });
        const sortedSheets = sheets.sort((a, b) => {
          return a.order - b.order;
        });
        return (
          <SheetList
            key={`sheetlist_${list}`}
            name={list}
            sheets={sortedSheets}
            getSheets={getSheets}
            editSheets={editSheets}
            sheetInfo={gotoSheetInfo}
            editStatus={openMenu}
            expanded={expandedPanel}
            expandedPanelChange={changeExpandedPanel}
          />
        );
      });
  };

  const changeSubject = (subject: string) => {
    props.history.push(`/sheetlist/${subject}`);
    ls.set('subjExpandedPanel', '');
    setExpandedPanel('');
  };

  const changeExpandedPanel = (val: string) => {
    if (val === expandedPanel) {
      setExpandedPanel('');
      ls.set('subjExpandedPanel', '');
    } else {
      setExpandedPanel(val);
      ls.set('subjExpandedPanel', val);
    }
  };

  useEffect(() => {
    const panelKey = ls.get('subjExpandedPanel') as string;
    if (panelKey) {
      setExpandedPanel(panelKey);
    }
    getSheets();
    setActiveLevel('b');
  }, [selectedSubj]);

  return (
    <MainContainer>
      {sheet.onFetch ? (
        <LinearProgress color="secondary" style={{ zIndex: 3 }} />
      ) : null}
      <div className={styles.topnav}>{genNav()}</div>
      <div className={styles.subtopnav}>{genSubnav()}</div>
      <div className={styles.container}>{genSheetList()}</div>
      <Fab
        color="secondary"
        aria-label="Add"
        className={styles.fab}
        onClick={() => toggleDialog(true)}
      >
        <AddIcon />
      </Fab>
      <AddSheetDialog
        isOpen={isOpenDialog}
        onClose={() => toggleDialog(false)}
      />
      {selectedSheet ? (
        <EditSheetDialog
          isOpen={isOnEdit}
          sheetInfo={selectedSheet}
          onClose={() => {
            setSelectedSheet(null);
            setIsOnEdit(false);
          }}
        />
      ) : null}
      <Menu
        id="changeStatusMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => changeSheetStatus(SheetStatus.Approved)}>
          {SheetStatus.Approved}
        </MenuItem>
        <MenuItem onClick={() => changeSheetStatus(SheetStatus.PostChecked)}>
          Checked2
        </MenuItem>
        <MenuItem onClick={() => changeSheetStatus(SheetStatus.PreChecked)}>
          Checked1
        </MenuItem>
        <MenuItem onClick={() => changeSheetStatus(SheetStatus.Rejected)}>
          {SheetStatus.Rejected}
        </MenuItem>
        <MenuItem onClick={() => changeSheetStatus(SheetStatus.Submitted)}>
          {SheetStatus.Submitted}
        </MenuItem>
        <MenuItem onClick={() => changeSheetStatus(SheetStatus.WIP)}>
          {SheetStatus.WIP}
        </MenuItem>
      </Menu>
    </MainContainer>
  );
});
