import React, { useEffect, useState, useRef } from 'react';
import styles from './UploadSheet.module.scss';
import MainContainer from '../components/MainContainer';
import {
  fetchSheetById,
  fetchFileById,
  uploadSheet,
  editSheetMaterials,
} from '../services/sheet';
import ErrorText from '../components/ErrorText';
import { SheetInfo } from '../state/ducks/sheet/types';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  TextField,
  DialogActions,
  Button,
  Fab,
} from '@material-ui/core';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import CloudUpload from '@material-ui/icons/CloudUpload';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { History } from 'history';
import UploadSheetDialog from '../components/UploadSheetDialog';

interface UploadSheetProps {
  match: { params: { id: string } };
  history: History;
}

export default (props: UploadSheetProps) => {
  const sheetId = props.match.params.id;
  const [onFetch, setOnFetch] = useState(false);
  const [fetchError, setFetchStatus] = useState(false);
  const [thisSheet, setSheet] = useState<SheetInfo>();
  const [uploadedSheet, setUploaded] = useState<string[]>();
  // for upload progress
  const [showLoader, toggleLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  // for edit sheet data
  const [onDataEdit, setOnDataEdit] = useState({ edited: false, type: '' });
  const [newSheetData, setNewSheetData] = useState('');
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const fetchData = async () => {
    setOnFetch(true);
    try {
      const [sheet, file] = await Promise.all([
        fetchSheetById(sheetId),
        fetchFileById(sheetId),
      ]);
      setUploaded(file.data.fileNames);
      setSheet(sheet.data);
      setFetchStatus(false);
      setOnFetch(false);
    } catch (error) {
      setFetchStatus(true);
      setOnFetch(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sheetId]);

  const progressHandler = (progressEvent: ProgressEvent) => {
    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  };

  const getFile = (path: string) => {
    props.history.push(`/viewer/${path}`);
  };

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (thisSheet && e.target.files && window.confirm('Upload file?')) {
      toggleLoader(true);
      const form = new FormData();
      form.append('files', e.target.files[0], `${e.target.id}`);
      uploadSheet(thisSheet._id, form, progressHandler)
        .then(() => {
          toggleLoader(false);
          fetchData();
        })
        .catch(err => {
          toggleLoader(false);
        });
    }
  };

  const handlerUpload = (input: HTMLInputElement | null) => {
    if (input) {
      input.click();
    }
  };

  const handleDelete = (name: string, type: string) => {
    if (window.confirm(`Are you sure to delete ${name}?`)) {
      setOnFetch(true);
      if (type === 'videoName') {
        editSheetMaterials(sheetId, { videoName: name })
          .then(() => {
            fetchData();
          })
          .catch(err => {
            setOnFetch(false);
          });
      } else {
        if (thisSheet) {
          let newSheetMaterial;
          if (type === 'reviewNames' || type === 'lectureNames') {
            newSheetMaterial = thisSheet[type].filter(e => e !== name);
          } else if (type === 'exercises' || type === 'answerKeys') {
            newSheetMaterial = thisSheet[type].filter(e => e.name !== name);
          }
          editSheetMaterials(sheetId, { [type]: newSheetMaterial })
            .then(() => {
              fetchData();
            })
            .catch(err => {
              setOnFetch(false);
            });
        }
      }
    }
  };

  const editSheetData = () => {
    if (thisSheet && window.confirm('Are you sure?')) {
      const type = onDataEdit.type;
      if (type === 'videoName') {
        editSheetMaterials(sheetId, { videoName: newSheetData })
          .then(() => {
            setOnDataEdit({ edited: false, type: '' });
            fetchData();
          })
          .catch(err => {
            setOnDataEdit({ edited: false, type: '' });
          });
      } else if (type === 'lectureNames' || type === 'reviewNames') {
        editSheetMaterials(sheetId, {
          [type]: [...thisSheet[type], newSheetData],
        })
          .then(() => {
            setOnDataEdit({ edited: false, type: '' });
            fetchData();
          })
          .catch(err => {
            setOnDataEdit({ edited: false, type: '' });
          });
      } else if (type === 'exercises' || type === 'answerKeys') {
        editSheetMaterials(sheetId, {
          [type]: [...thisSheet[type], { name: newSheetData, order: 1 }],
        })
          .then(() => {
            setOnDataEdit({ edited: false, type: '' });
            fetchData();
          })
          .catch(err => {
            setOnDataEdit({ edited: false, type: '' });
          });
      }
    }
  };

  const fileList = (title: string[], type: string, canDelete = true) => {
    const list = [];
    for (let i = 0; i < title.length; i++) {
      let bool = false;
      let foundSheet = '';
      if (uploadedSheet) {
        for (let j = 0; j < uploadedSheet.length; j++) {
          if (
            title[i] ===
            uploadedSheet[j].slice(uploadedSheet[j].indexOf('/') + 1)
          ) {
            bool = true;
            foundSheet = uploadedSheet[j];
          }
        }
      }
      const inputRef = useRef<HTMLInputElement>(null);
      list.push(
        <div key={`${title[i]}`} className={styles.file_list}>
          <p className={styles.file_title}>{title[i]}</p>
          {bool ? (
            <RemoveRedEye
              className={styles.see_sheet}
              onClick={() => getFile(foundSheet)}
            />
          ) : (
            <RemoveRedEye className={styles.disabled} />
          )}
          <CloudUpload
            className={styles.upload}
            onClick={() => handlerUpload(inputRef.current)}
          />
          {canDelete ? (
            <DeleteForever
              className={styles.trash}
              onClick={() => handleDelete(title[i], type)}
            />
          ) : null}
          <input
            id={title[i]}
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={e => uploadFile(e)}
          />
        </div>
      );
    }
    return list;
  };

  const SheetPage = () => {
    if (thisSheet) {
      return (
        <div className={styles.container}>
          <div className={styles.title_container}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => goBack()}
            >
              Back
            </Button>
            <h1 className={styles.title}>{thisSheet.code}</h1>
            <div />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => getFile(`${sheetId}/fullsheet`)}
            >
              FULL
            </Button>
          </div>
          <div className={styles.header_container}>
            <h1 className={styles.header}>Video</h1>
            <h1
              className={styles.header_btn}
              onClick={() => setOnDataEdit({ edited: true, type: 'videoName' })}
            >
              Edit
            </h1>
          </div>
          <Divider />
          {fileList([thisSheet.videoName], 'videoName', false)}
          <div className={styles.header_container}>
            <h1 className={styles.header}>Lecture</h1>
            <h1
              className={styles.header_btn}
              onClick={() =>
                setOnDataEdit({ edited: true, type: 'lectureNames' })
              }
            >
              +
            </h1>
          </div>
          <Divider />
          {fileList(thisSheet.lectureNames, 'lectureNames')}
          <div className={styles.header_container}>
            <h1 className={styles.header}>Exercises</h1>
            <h1
              className={styles.header_btn}
              onClick={() => setOnDataEdit({ edited: true, type: 'exercises' })}
            >
              +
            </h1>
          </div>
          <Divider />
          {fileList(thisSheet.exercises.map(e => e.name), 'exercises')}
          <div className={styles.header_container}>
            <h1 className={styles.header}>Review</h1>
            <h1
              className={styles.header_btn}
              onClick={() =>
                setOnDataEdit({ edited: true, type: 'reviewNames' })
              }
            >
              +
            </h1>
          </div>
          <Divider />
          {fileList(thisSheet.reviewNames, 'reviewNames')}
          <div className={styles.header_container}>
            <h1 className={styles.header}>Answers</h1>
            <h1
              className={styles.header_btn}
              onClick={() =>
                setOnDataEdit({ edited: true, type: 'answerKeys' })
              }
            >
              +
            </h1>
          </div>
          <Divider />
          {fileList(thisSheet.answerKeys.map(e => e.name), 'answerKeys')}
        </div>
      );
    } else {
      return null;
    }
  };

  const goBack = () => {
    if (thisSheet) {
      const path = thisSheet.code.toLowerCase().substr(0, 2);
      props.history.push(`/sheetlist/${path}`);
    }
  };

  return (
    <MainContainer>
      {onFetch ? <LinearProgress color="secondary" /> : null}
      {fetchError ? <ErrorText /> : <SheetPage />}
      <Fab
        color="secondary"
        aria-label="Add"
        className={styles.fab}
        onClick={() => setShowUploadDialog(true)}
      >
        <CloudUpload />
      </Fab>
      <UploadSheetDialog
        sheetId={sheetId}
        show={showUploadDialog}
        close={setShowUploadDialog}
        fetch={fetchData}
        onFetch={toggleLoader}
        progress={progressHandler}
      />
      <Dialog
        fullWidth
        open={onDataEdit.edited}
        onClose={() => setOnDataEdit({ edited: false, type: '' })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{onDataEdit.type} name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Name"
            onChange={e => {
              setNewSheetData(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => editSheetData()}>
            {onDataEdit.type === 'videoName' ? 'Edit' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={showLoader}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{`Uploading(${progress}%)`}</DialogTitle>
        <DialogContent>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={progress}
          />
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};
