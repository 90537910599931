import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import MainContainer from '../components/MainContainer';
import { allStudent, Student } from '../services/student';
import PortfolioList from '../components/PortfolioList';
import { ReactComponent as PlanLebel } from '../assets/images/planlabel.svg';
import { History } from 'history';

interface HomeProps {
  history: History;
}

export default (props: HomeProps) => {
  const { history } = props;
  const [students, setStudents] = useState<Student[]>([]);
  const gotoPlanList = () => {
    history.push('/planlist');
  };

  useEffect(() => {
    allStudent()
      .then(({ data }) => {
        setStudents(data.students);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <MainContainer>
      <div className={styles.container}>
        <div className={styles.banner}>
          <div className={styles.planlabel} onClick={gotoPlanList}>
            <PlanLebel />
          </div>
        </div>
        {students.map((e, index) => (
          <PortfolioList key={`list_${index}`} student={e} />
        ))}
      </div>
    </MainContainer>
  );
};
