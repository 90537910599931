import React from 'react';
import styles from './Unauthorized.module.scss';
import logo from '../assets/images/unauthorized.png';

export default () => {
  return (
    <div className={styles.background}>
      <div className={styles.title}>401</div>
      <img className={styles.img} src={logo} alt="unauthorized-logo"></img>
    </div>
  );
};
