import React, { useState, useEffect } from 'react';
import styles from './PlanManagementBox.module.scss';
import { SheetInPlan } from '../services/plan';

interface PlanManagementBoxProps {
  sheetPlan: SheetInPlan;
  setDragSheet: React.Dispatch<React.SetStateAction<string>>;
  swapSheet: (id: string) => void;
  remove: (id: string) => void;
}

export default (props: PlanManagementBoxProps) => {
  const { sheetPlan, setDragSheet, swapSheet, remove } = props;
  const [isDrag, setIsDrag] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [isTest, setIsTest] = useState(false);

  const drag = () => {
    setIsDrag(true);
    setDragSheet(sheetPlan._id);
  };
  const dragEnd = () => {
    setIsDrag(false);
    setDragSheet('');
  };
  const dragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setIsOver(true);
  };
  const dragLeave = () => {
    setIsOver(false);
  };
  const drop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setIsOver(false);
    swapSheet(sheetPlan._id);
  };
  const removeHandle = () => {
    remove(sheetPlan._id);
  };

  useEffect(() => {
    const sheetReg = /[PCMEBS][KJHNUE]-\w{1,4}\d{2}([T]?)\(\d+\.\d+\.\d+\)/;
    const exec = sheetReg.exec(sheetPlan.code);
    if (exec && exec[1] === 'T') {
      setIsTest(true);
    } else {
      setIsTest(false);
    }
  }, [sheetPlan]);

  return (
    <div
      className={`${styles.container} ${isDrag ? styles.hidden : ''} ${
        isOver ? styles.over : ''
      }`}
      draggable
      onDoubleClick={removeHandle}
      onDrag={drag}
      onDragEnd={dragEnd}
      onDragOver={dragOver}
      onDragLeave={dragLeave}
      onDrop={drop}
    >
      <div className={`${styles.box} ${isTest ? styles.test : ''}`}>
        {sheetPlan.code}
      </div>
    </div>
  );
};
