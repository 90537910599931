import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Button,
} from '@material-ui/core';
import styles from './EditSheetDialog.module.scss';
import {
  SheetInfo,
  SheetClass,
  SheetInfoType,
  Subject,
  SheetEdit,
} from '../state/ducks/sheet/types';
import { editSheetInfo } from '../services/sheet';
import { getSheets } from '../state/ducks/sheet/operations';
import { connect } from 'react-redux';

type EditSheetDialogProps = {
  isOpen: boolean;
  onClose: () => any;
  sheetInfo: SheetInfo;
  getSheets: () => Promise<void>;
};

const mapDispatchToProps = { getSheets };

export default connect(
  null,
  mapDispatchToProps
)((props: EditSheetDialogProps) => {
  const { isOpen, onClose, sheetInfo, getSheets } = props;
  const [newSheetInfo, setNewSheetInfo] = useState(sheetInfo);
  const [onFetch, setOnFetch] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const checkCode = (code: string) => {
    const sheetReg = /([PCMEBS])([KJHNUE])-\w{1,4}(\d{2})([T]?)\(\d+\.\d+\.\d+\)/;
    setNewSheetInfo({ ...newSheetInfo, code });
    setCanEdit(canEdit || sheetReg.test(code));
  };

  const handleEdit = (fieldName: keyof SheetEdit, value: any) => {
    if (fieldName === 'code') {
      checkCode(value);
    } else {
      setCanEdit(true);
      setNewSheetInfo({ ...newSheetInfo, [fieldName]: value });
    }
  };

  const getDiff = (
    oldInfo: SheetInfo,
    newInfo: SheetInfo
  ): Partial<SheetEdit> => {
    return Object.keys(oldInfo).reduce(
      (acc: Partial<SheetInfo>, key: string) => {
        const k = key as keyof SheetEdit;
        return oldInfo[k] === newInfo[k] ? acc : { ...acc, [k]: newInfo[k] };
      },
      {} as Partial<SheetInfo>
    );
  };

  const submit = async () => {
    try {
      setOnFetch(true);
      await editSheetInfo(sheetInfo._id, getDiff(sheetInfo, newSheetInfo));
      await getSheets();
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setOnFetch(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()} fullWidth>
      <DialogTitle>Edit sheet</DialogTitle>
      <DialogContent>
        <form className={styles.form}>
          <TextField
            className={styles.input}
            autoFocus
            fullWidth
            value={newSheetInfo.code}
            label="Sheet name"
            helperText="ex.PH-TB01(1.1.1)"
            onChange={e => handleEdit('code', e.target.value)}
          />
          <TextField
            className={styles.input}
            fullWidth
            label="Sheet title"
            value={newSheetInfo.title}
            helperText="ex.DC Circuit"
            onChange={e => handleEdit('title', e.target.value)}
          />
          <FormControl fullWidth className={styles.input}>
            <InputLabel>Subject</InputLabel>
            <Select
              native
              value={newSheetInfo.subject}
              onChange={e => handleEdit('subject', e.target.value)}
            >
              <option value={Subject.MATH}>{Subject.MATH}</option>
              <option value={Subject.PHYSICS}>{Subject.PHYSICS}</option>
              <option value={Subject.CHEMISTRY}>{Subject.CHEMISTRY}</option>
              <option value={Subject.ENGLISH}>{Subject.ENGLISH}</option>
              <option value={Subject.BIOLOGY}>{Subject.BIOLOGY}</option>
            </Select>
          </FormControl>
          <FormControl fullWidth className={styles.input}>
            <InputLabel>Level</InputLabel>
            <Select
              native
              value={newSheetInfo.class}
              onChange={e => handleEdit('class', e.target.value as SheetClass)}
            >
              <option value={SheetClass.NURSERY}>{SheetClass.NURSERY}</option>
              <option value={SheetClass.KIDS}>{SheetClass.KIDS}</option>
              <option value={SheetClass.JUNIOR}>{SheetClass.JUNIOR}</option>
              <option value={SheetClass.HIGH}>{SheetClass.HIGH}</option>
              <option value={SheetClass.UNIVERSITY}>
                {SheetClass.UNIVERSITY}
              </option>
              <option value={SheetClass.EVERYDAY}>{SheetClass.EVERYDAY}</option>
            </Select>
          </FormControl>
          <TextField
            className={styles.input}
            fullWidth
            label="Order"
            type="number"
            value={newSheetInfo.order}
            onChange={e => handleEdit('order', Number(e.target.value))}
          />
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              native
              value={newSheetInfo.type}
              onChange={e =>
                handleEdit('type', e.target.value as SheetInfoType)
              }
            >
              <option value={SheetInfoType.SHEET}>SHEET</option>
              <option value={SheetInfoType.TEST}>TEST</option>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={submit}
          disabled={!onFetch && !canEdit}
        >
          Edit
        </Button>
        <Button onClick={onClose} className={styles.danger}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});
