import React from 'react';
import styles from './PlanBox.module.scss';
import { deletePlan, PlanWithOutCode } from '../services/plan';
import { Delete } from '@material-ui/icons';

interface PlanBoxProps {
  plan: PlanWithOutCode;
  goTo: (planId: string) => void;
  fetch: () => void;
}

export default (props: PlanBoxProps) => {
  const { plan, goTo, fetch } = props;

  const deleteThisPlan = async (planId: string) => {
    if (window.confirm('Delete this plan?')) {
      try {
        await deletePlan(planId);
        fetch();
      } catch (error) {
        // empty
      }
    }
  };

  return (
    <div className={styles.box}>
      <p className={styles.text} onClick={() => goTo(plan._id)}>
        {plan.name}
      </p>
      <Delete
        className={styles.deleteBtn}
        onClick={() => deleteThisPlan(plan._id)}
      />
    </div>
  );
};
