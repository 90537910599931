import React from 'react';
import Topnav from './Topnav';
import styles from './MainContainer.module.scss';

interface MainContainerProps {
  children: any;
}

export default (props: MainContainerProps) => {
  const { children } = props;
  return (
    <div>
      <Topnav />
      <div className={styles.content} role="main">
        {children}
      </div>
    </div>
  );
};
