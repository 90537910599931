import React, { useEffect, useState } from 'react';
import styles from './PlanList.module.scss';
import MainContainer from '../components/MainContainer';
import { getPlan, createNewPlan, PlanWithOutCode } from '../services/plan';
import PlanBox from '../components/PlanBox';
import { History } from 'history';
import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

interface PlanListProps {
  history: History;
}

export default (props: PlanListProps) => {
  const { history } = props;
  const [plans, setPlans] = useState<PlanWithOutCode[]>([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [planName, setPlanName] = useState('');

  const fetch = () => {
    getPlan()
      .then(({ data }) => {
        setPlans(data.plans);
      })
      .catch(err => console.log(err));
  };

  const goToPlan = (planId: string) => {
    history.push(`planlist/${planId}`);
  };

  const addNewPlan = async () => {
    try {
      await createNewPlan(planName);
      fetch();
      setPlanName('');
      setIsOpenDialog(false);
    } catch (error) {
      setPlanName('');
      setIsOpenDialog(false);
    }
  };

  const openDialog = () => {
    setIsOpenDialog(true);
  };

  const closeDialog = () => {
    setIsOpenDialog(false);
  };

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setPlanName(ev.target.value);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <MainContainer>
      <div className={styles.container}>
        {plans.map((el, index) => (
          <PlanBox
            key={`planbox_${index}`}
            plan={el}
            goTo={goToPlan}
            fetch={fetch}
          />
        ))}
      </div>
      <div className={styles.addBtn}>
        <Fab aria-label="add" className={styles.whiteBtn} onClick={openDialog}>
          <AddIcon />
        </Fab>
      </div>
      <Dialog open={isOpenDialog} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle>Add new plan</DialogTitle>
        <DialogContent>
          <form
            onSubmit={ev => {
              ev.preventDefault();
              addNewPlan();
            }}
          >
            <TextField
              autoFocus
              type="text"
              fullWidth
              onChange={handleInputChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={addNewPlan}>ADD +</Button>
          <Button onClick={closeDialog}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
};
