import React, { useEffect, useState } from 'react';
import styles from './QR.module.scss';
import { Option } from '../components/Autocomplete';
import SelectSheet from '../components/SelectSheet';
import { connect } from 'react-redux';
import { getSheets } from '../state/ducks/sheet/operations';
import SheetQR from '../components/SheetQR';
import MainContainer from '../components/MainContainer';

const mapDispatchToProps = { getSheets };

interface QRProps {
  getSheets: () => Promise<void>;
}

export default connect(
  null,
  mapDispatchToProps
)((props: QRProps) => {
  const { getSheets } = props;
  const [selectedSheet, setSelectedSheet] = useState<Option | null>(null);

  useEffect(() => {
    getSheets();
  }, []);

  return (
    <MainContainer>
      <div className={styles.content}>
        <SelectSheet
          value={selectedSheet}
          onChange={e => setSelectedSheet(e)}
        />
        {selectedSheet ? (
          <SheetQR
            sheetId={selectedSheet.value}
            sheetCode={selectedSheet.label}
          />
        ) : null}
      </div>
    </MainContainer>
  );
});
