import { SheetInfo, SheetAction, SheetType } from './types';

export const setInfo = (info: SheetInfo[]): SheetAction => {
  return {
    type: SheetType.INFO,
    payload: { info },
  };
};

export const setOnFetch = (onFetch: boolean): SheetAction => {
  return {
    type: SheetType.FETCH,
    payload: { onFetch },
  };
};
