import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Navlist.module.scss';

export default () => {
  return (
    <React.Fragment>
      <div className={styles.seperator}></div>
      <NavLink
        className={styles.link}
        activeClassName={styles.active}
        exact
        to={'/home'}
      >
        <p>HOME</p>
      </NavLink>
      <div className={styles.seperator}></div>
      <NavLink
        className={styles.link}
        activeClassName={styles.active}
        to={'/sheetlist'}
      >
        <p>SHEET</p>
      </NavLink>
      <div className={styles.seperator}></div>
      <NavLink
        className={styles.link}
        activeClassName={styles.active}
        to={'/qr'}
      >
        <p>QR GENERATOR</p>
      </NavLink>
      <div className={styles.seperator}></div>
      <NavLink
        className={styles.link}
        activeClassName={styles.active}
        to={'/sheetprinting'}
      >
        <p>PRINTING</p>
      </NavLink>
      <div className={styles.seperator}></div>
    </React.Fragment>
  );
};
