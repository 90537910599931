import React, { useEffect, useState } from 'react';
import { RouteProps, Route } from 'react-router';
import { login } from '../services/authentication';
import localforage from 'localforage';

export default (props: RouteProps) => {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    authen();
  }, []);

  const authen = async () => {
    const token = window.sessionStorage.getItem('token');
    const exp = window.sessionStorage.getItem('exp');
    if (token && exp && Date.now() < new Date(+exp * 1000).getTime()) {
      const username = window.sessionStorage.getItem('username') || '';
      if (!/^99[0-9]{3}$/g.test(username)) {
        window.location.replace('/401');
      }
      setIsLogin(true);
    } else {
      login();
    }
  };

  if (isLogin) {
    return <Route {...props} />;
  } else {
    return (
      <h3 style={{ color: 'white', marginLeft: '24px' }}>
        Checking authorization...
      </h3>
    );
  }
};
