import React, { useState } from 'react';
import { getSheets } from '../state/ducks/sheet/operations';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './AddSheetDialog.module.scss';
import { Subject, SheetClass, SheetInfoType } from '../state/ducks/sheet/types';
import { extractCode, createSheet } from '../services/sheet';

const mapDispatchToProps = { getSheets };

interface AddSheetDialogProps {
  isOpen: boolean;
  onClose: () => void;
  getSheets: () => Promise<void>;
}

export default connect(
  null,
  mapDispatchToProps
)((props: AddSheetDialogProps) => {
  const getSheets = props.getSheets;
  const isOpenDialog = props.isOpen;
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [subj, setSubj] = useState<Subject>(Subject.MATH);
  const [level, setLevel] = useState<SheetClass>(SheetClass.KIDS);
  const [order, setOrder] = useState(0);
  const [type, setType] = useState<SheetInfoType>(SheetInfoType.SHEET);
  const [canAdd, setCanAdd] = useState(false);

  const closeDialog = () => {
    setCode('');
    setTitle('');
    props.onClose();
  };

  const submit = () => {
    props.onClose();
    createSheet(code, title, subj, level, order, type)
      .then(() => {
        getSheets();
      })
      .catch(err => {
        props.onClose();
        alert('Error, try again.');
      });
  };

  const setAndExtractCode = (value: string) => {
    const data = value.toUpperCase();
    setCode(data);
    const extract = extractCode(value);
    setSubj(extract.subject);
    setLevel(extract.sheetClass);
    setType(extract.sheetType);
    setOrder(extract.order);
    setCanAdd(extract.isCorrect);
  };

  return (
    <Dialog open={isOpenDialog} onClose={() => closeDialog()} fullWidth>
      <DialogTitle>Add new sheet</DialogTitle>
      <DialogContent>
        <form className={styles.form}>
          <TextField
            className={styles.input}
            autoFocus
            fullWidth
            label="Sheet name"
            helperText="ex.PH-TB01(1.1.1)"
            onChange={e => {
              setAndExtractCode(e.target.value);
            }}
          />
          <TextField
            className={styles.input}
            fullWidth
            label="Sheet title"
            helperText="ex.DC Circuit"
            onChange={e => {
              setTitle(e.target.value);
            }}
          />
          <FormControl fullWidth className={styles.input}>
            <InputLabel>Subject</InputLabel>
            <Select
              native
              value={subj}
              onChange={e => {
                setSubj(e.target.value as Subject);
              }}
            >
              <option value={Subject.MATH}>{Subject.MATH}</option>
              <option value={Subject.PHYSICS}>{Subject.PHYSICS}</option>
              <option value={Subject.CHEMISTRY}>{Subject.CHEMISTRY}</option>
              <option value={Subject.ENGLISH}>{Subject.ENGLISH}</option>
              <option value={Subject.BIOLOGY}>{Subject.BIOLOGY}</option>
            </Select>
          </FormControl>
          <FormControl fullWidth className={styles.input}>
            <InputLabel>Level</InputLabel>
            <Select
              native
              value={level}
              onChange={e => {
                setLevel(e.target.value as SheetClass);
              }}
            >
              <option value={SheetClass.NURSERY}>{SheetClass.NURSERY}</option>
              <option value={SheetClass.KIDS}>{SheetClass.KIDS}</option>
              <option value={SheetClass.JUNIOR}>{SheetClass.JUNIOR}</option>
              <option value={SheetClass.HIGH}>{SheetClass.HIGH}</option>
              <option value={SheetClass.UNIVERSITY}>{SheetClass.UNIVERSITY}</option>
              <option value={SheetClass.EVERYDAY}>{SheetClass.EVERYDAY}</option>
            </Select>
          </FormControl>
          <TextField
            className={styles.input}
            fullWidth
            label="Order"
            type="number"
            value={order}
            onChange={e => {
              setOrder(Number(e.target.value));
            }}
          />
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              native
              value={type}
              onChange={e => {
                setType(e.target.value as SheetInfoType);
              }}
            >
              <option value="SHEET">SHEET</option>
              <option value="TEST">TEST</option>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => submit()} disabled={!canAdd}>
          Add+
        </Button>
        <Button onClick={() => closeDialog()} className={styles.danger}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});
