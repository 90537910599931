import React, { useEffect, useState } from 'react';
import styles from './StudentSheetPrinting.module.scss';
import { SheetInfo, Subject, SheetInfoType } from '../state/ducks/sheet/types';
import MainContainer from '../components/MainContainer';
import returnIcon from '../assets/icons/sharp-keyboard_return-24px.svg';
import customSheet from '../assets/icons/custom-sheet.svg';
import vdoBtn from '../assets/icons/vdo-btn.svg';
import vdoSheetBtn from '../assets/icons/vdosheet-btn.svg';
import sheetBtn from '../assets/icons/sheet-btn.svg';
import seperator from '../assets/images/seperator.svg';
import {
  studentInfo,
  getPortfolio,
  Portfolio,
  addPortfolio,
  printSheet,
} from '../services/student';
import { History } from 'history';
import { CircularProgress } from '@material-ui/core';
import { fetchFileById, fetchSheet } from '../services/sheet';
import { fetchFile } from '../services/sheet';
import firebase from 'firebase/app';
import 'firebase/database';

interface StudentSheetPrintingProps {
  match: { params: { studentId: string } };
  history: History;
}

let firebaseRef: firebase.database.Reference;

export default (props: StudentSheetPrintingProps) => {
  const studentId = props.match.params.studentId;
  const sheetReg = /([PCMEBS][KJHNUE])-(\w{1,3})(\w{1})\d{2}[T]?\((\d+)\.\d+\.\d+\)/;
  const { history } = props;
  const [studentName, setStudentName] = useState('');
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [printing, setPrinting] = useState<SheetInfo | null>(null);
  const [onPrint, setOnPrint] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [current, setCurrent] = useState<SheetInfo | null>(null);
  const [next, setNext] = useState<SheetInfo | null>(null);

  const handlePrint = async (type: string) => {
    if (printing) {
      setOnPrint(true);
      const data = (await fetchFileById(printing._id)).data;
      if (type === '' || type === 'vdo') {
        setLoadingText('Add student history');
        try {
          await addPortfolio(studentId.slice(0, -1), printing._id);
          firebaseRef = firebase.database().ref('/live/printing');
          if (firebaseRef) {
            firebaseRef.update({
              [`${studentId.slice(0, -1)}`]: printing._id,
            });
          }
        } catch (error) {
          // empty
        }
      }
      if (type === '' || type === 'print') {
        setLoadingText('Printing');
        try {
          const lct = [];
          const exm = [];
          const exr = [];
          const rvw = [];
          for (const i of data.fileNames) {
            if (i.indexOf('lecturePdf1') >= 0) {
              lct.push(i);
            }

            if (i.indexOf('examplePdf1') >= 0) {
              exm.push(i);
            }

            if (i.indexOf('exercisePdf1') >= 0) {
              exr.push(i);
            }

            if (i.indexOf('reviewPdf1') >= 0) {
              rvw.push(i);
            }
          }
          const files = lct.concat(exm, exr, rvw);
          const promise = [];
          for (const i of files) {
            promise.push(fetchFile(i));
          }
          const signUrl = (await Promise.all(promise)).map(
            e => e.data.signedUrl
          );
          await printSheet(signUrl);
        } catch (error) {
          // empty
        }
      }
      history.goBack();
    }
  };

  const sheetHistory = () => {
    const res = [];
    if (portfolios.length > 0) {
      const lastFive = portfolios.slice(-3);
      for (const i of lastFive) {
        res.push(
          <div
            key={`sheetHistory_${i.sheet._id}`}
            className={styles.historyBox}
          >
            {i.sheet.code}
          </div>
        );
      }
    }
    return res;
  };
  const goback = () => {
    history.goBack();
  };
  const gotoCustomSheet = () => {
    history.push(`/sheetprinting/${studentId}/custom`);
  };

  const fetchData = async (id: string, subj: Subject) => {
    try {
      const infoData = await studentInfo(id);
      setStudentName(`${infoData.data.firstname} (${infoData.data.nickname})`);
    } catch (error) {
      // empty
    }
    try {
      const [sheetData, portData] = await Promise.all([
        fetchSheet(),
        getPortfolio(id, subj),
      ]);
      const ports = portData.data.sheets;
      if (ports.length > 0) {
        setPortfolios(ports);
        const lastSheet = ports[ports.length - 1];
        setCurrent(lastSheet.sheet);
        const sheetExec = sheetReg.exec(lastSheet.sheet.code);
        if (sheetExec && sheetExec[1]) {
          const filteredSheet = sheetData.data.filter(e => {
            const exec = sheetReg.exec(e.code);
            if (exec && exec[1]) {
              return e.subject === subj && exec[1] === sheetExec[1];
            } else {
              return false;
            }
          });
          const sortedSheet = filteredSheet.sort((a, b) => {
            const execA = sheetReg.exec(a.code);
            const execB = sheetReg.exec(b.code);
            if (execA && execB) {
              const codeA = `${execA[3]}${execA[1]}${execA[2]}${execA[4]}`;
              const codeB = `${execB[3]}${execB[1]}${execB[2]}${execB[4]}`;
              if (codeA === codeB) {
                return a.order - b.order;
              } else if (codeA > codeB) {
                return 1;
              } else {
                return -1;
              }
            } else {
              return -1;
            }
          });
          const currentIndex = sortedSheet.findIndex(
            e => e._id === lastSheet.sheet._id
          );
          if (currentIndex < sortedSheet.length - 1) {
            const nextSheet = sortedSheet[currentIndex + 1];
            if (
              nextSheet.type === SheetInfoType.TEST ||
              lastSheet.sheet.type === SheetInfoType.TEST
            ) {
              setNext(sortedSheet[currentIndex + 2]);
              setPrinting(sortedSheet[currentIndex + 2]);
            } else if (
              currentIndex !== 0 &&
              sortedSheet[currentIndex - 1].type === SheetInfoType.TEST
            ) {
              setNext(sortedSheet[currentIndex - 1]);
              setPrinting(sortedSheet[currentIndex - 1]);
            } else {
              setNext(nextSheet);
              setPrinting(nextSheet);
            }
          }
        }
      }
    } catch (error) {
      // empty
    }
  };

  useEffect(() => {
    let subj = Subject.MATH;
    switch (studentId.slice(-1)) {
      case '2':
        subj = Subject.PHYSICS;
        break;
      case '3':
        subj = Subject.CHEMISTRY;
        break;
      case '4':
        subj = Subject.ENGLISH;
        break;
      default:
        break;
    }
    fetchData(studentId.slice(0, -1), subj);
  }, [studentId]);

  return (
    <MainContainer>
      {onPrint ? (
        <div className={styles.overlayBG}>
          <div className={styles.dialog}>
            <CircularProgress size={130} color={'secondary'} thickness={6} />
            <p className={styles.loadingText}>{loadingText}</p>
          </div>
        </div>
      ) : null}
      <div className={styles.container}>
        <div className={styles.header}>
          <img
            src={returnIcon}
            alt="backBtn"
            onClick={goback}
            style={{ cursor: 'pointer' }}
          />
          <p className={styles.studentName}>
            {studentName} {studentId}
          </p>
        </div>
        <div className={styles.content}>
          <img
            src={customSheet}
            alt="backBtn"
            style={{ cursor: 'pointer' }}
            onClick={gotoCustomSheet}
          />
          <div className={styles.fullPortGrid}>
            <div className={styles.portCol}>
              <p className={styles.portLabel}>now</p>
              <div
                className={`${styles.portBox} ${
                  current && printing && current._id === printing._id
                    ? styles.active
                    : ''
                }`}
                onClick={() => setPrinting(current)}
              >
                {current ? current.code : ''}
              </div>
            </div>
            <div className={styles.portCol}>
              <p className={styles.portLabel}>next</p>
              <div
                className={`${styles.portBox} ${
                  next && printing && next._id === printing._id
                    ? styles.active
                    : ''
                }`}
                onClick={() => setPrinting(next)}
              >
                {next ? next.code : ''}
              </div>
            </div>
          </div>
          <div className={styles.iconBox}>
            <img
              src={vdoBtn}
              alt="vdoBtn"
              style={{ cursor: 'pointer' }}
              onClick={() => handlePrint('vdo')}
            />
            <img
              src={vdoSheetBtn}
              alt="vdoSheetBtn"
              style={{ cursor: 'pointer' }}
              onClick={() => handlePrint('')}
            />
            <img
              src={sheetBtn}
              alt="sheetBtn"
              style={{ cursor: 'pointer' }}
              onClick={() => handlePrint('sheet')}
            />
          </div>
        </div>
        <div className={styles.seperator}>
          <p className={styles.seperatorLabel}>HISTORY</p>
          <object
            type="image/svg+xml"
            data={seperator}
            style={{ width: '100%' }}
            title="History_line"
          />
        </div>
        <div className={styles.footer}>{sheetHistory()}</div>
      </div>
    </MainContainer>
  );
};
