import { Sheet, SheetType, SheetAction } from './types';

const initialState: Sheet = {
  onFetch: false,
  info: [],
};

const sheet = (state = initialState, action: SheetAction) => {
  switch (action.type) {
    case SheetType.FETCH:
      return { ...state, onFetch: action.payload.onFetch || false };
    case SheetType.INFO:
      return { ...state, info: action.payload.info || [] };
    default:
      return state;
  }
};

export default sheet;
