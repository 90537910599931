import React, { useEffect } from 'react';
import { getSheets } from '../state/ducks/sheet/operations';
import { connect } from 'react-redux';
import { AppState } from '../state/ducks';
import { Sheet, SheetStatus } from '../state/ducks/sheet/types';
import styles from './SheetSubj.module.scss';
import { LinearProgress } from '@material-ui/core';
import { History } from 'history';
import MainContainer from '../components/MainContainer';
import { getSubjOrder } from '../utils/sortOrder';

const mapStateToProps = (state: AppState) => ({
  sheet: state.sheet,
});

const mapDispatchToProps = { getSheets };

interface SheetSubjProps {
  sheet: Sheet;
  getSheets: () => Promise<void>;
  history: History;
}

interface SheetGroup {
  name: string;
  approved: number;
  submitted: number;
  inprogress: number;
  reject: number;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: SheetSubjProps) => {
  const { sheet, getSheets } = props;

  const gotoThisSubj = (subj: string) => {
    props.history.push(`/sheetlist/${subj}`);
  };

  const countLevel = (level: string): number => {
    const sheetReg = /([PCMEBS])([KJHNUE])-(\w{1,4})(\d{2})/;
    let counter = 0;
    const tempArr: string[] = [];
    for (const i of sheet.info) {
      if (i.code.toLowerCase().substr(0, 2) === level) {
        const codeArr = sheetReg.exec(i.code);
        if (codeArr && codeArr[3] && tempArr.indexOf(codeArr[3]) < 0) {
          counter++;
          tempArr.push(codeArr[3]);
        }
      }
    }
    return counter;
  };

  const already = (sheetGroup: SheetGroup[], group: string) => {
    for (const i of sheetGroup) {
      if (i.name.indexOf(group) >= 0) {
        return true;
      }
    }
    return false;
  };

  const subjGroup = () => {
    if (sheet) {
      const subj: SheetGroup[] = [];
      for (const i of sheet.info) {
        if (already(subj, i.code.toLowerCase().substr(0, 2))) {
          for (const j of subj) {
            if (j.name === i.code.toLowerCase().substr(0, 2)) {
              if (i.status === SheetStatus.Approved) {
                j.approved += 1;
              } else if (
                i.status === SheetStatus.Submitted ||
                i.status === SheetStatus.PreChecked ||
                i.status === SheetStatus.PostChecked
              ) {
                j.submitted += 1;
              } else if (i.status === SheetStatus.Rejected) {
                j.reject += 1;
              } else {
                j.inprogress += 1;
              }
            }
          }
        } else {
          if (i.status === SheetStatus.Approved) {
            subj.push({
              name: i.code.toLowerCase().substr(0, 2),
              approved: 1,
              submitted: 0,
              inprogress: 0,
              reject: 0,
            });
          } else if (i.status === SheetStatus.Submitted) {
            subj.push({
              name: i.code.toLowerCase().substr(0, 2),
              approved: 0,
              submitted: 1,
              inprogress: 0,
              reject: 0,
            });
          } else if (i.status === SheetStatus.Rejected) {
            subj.push({
              name: i.code.toLowerCase().substr(0, 2),
              approved: 0,
              submitted: 0,
              inprogress: 0,
              reject: 1,
            });
          } else {
            subj.push({
              name: i.code.toLowerCase().substr(0, 2),
              approved: 0,
              submitted: 0,
              inprogress: 1,
              reject: 0,
            });
          }
        }
      }
      const sorted = subj.sort((a, b) => {
        return getSubjOrder(a.name) - getSubjOrder(b.name);
      });
      const res: JSX.Element[] = [];
      for (const i of sorted) {
        res.push(
          <div
            key={i.name}
            className={`${styles.main_grid} ${styles[i.name]}`}
            onClick={() => gotoThisSubj(i.name)}
          >
            <p className={`${styles.title} ${styles[i.name]}`}>
              {i.name.toUpperCase()}
            </p>
            <div className={styles.grid}>
              <div className={styles.subgrid}>
                <p className={styles.level}>LEVEL</p>
                <p className={styles.text}>{countLevel(i.name)}</p>
              </div>
              <div className={styles.subgrid}>
                <p className={styles.approved}>APPROVED</p>
                <p className={styles.text}>{i.approved}</p>
              </div>
              <div className={styles.subgrid}>
                <p className={styles.submitted}>SUBMITTED</p>
                <p className={styles.text}>{i.submitted}</p>
              </div>
              <div className={styles.subgrid}>
                <p className={styles.inprogress}>IN PROGRESS</p>
                <p className={styles.text}>{i.inprogress}</p>
              </div>
              <div className={styles.subgrid}>
                <p className={styles.rejected}>REJECTED</p>
                <p className={styles.text}>{i.reject}</p>
              </div>
            </div>
          </div>
        );
      }
      return res;
    } else {
      return null;
    }
  };

  useEffect(() => {
    getSheets();
  }, []);

  return (
    <MainContainer>
      <div className={styles.container}>
        {sheet.onFetch ? <LinearProgress color="secondary" /> : null}
        {subjGroup()}
      </div>
    </MainContainer>
  );
});
