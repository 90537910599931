import localforage from 'localforage'

export function login() {
  window.location.replace(
    `${process.env.REACT_APP_AUTH_URL}/auth?callback=${encodeURIComponent(
      process.env.REACT_APP_SITE_URL || ''
    )}/redirect`
  );
}

export async function logout() {
  await localforage.clear()
  window.location.replace('/')
}
