import { Dispatch } from '../types';
import { SheetAction } from './types';
import { setOnFetch, setInfo } from './actions';
import { fetchSheet } from '../../../services/sheet';

export const getSheets = () => {
  return async (dispatch: Dispatch<SheetAction>) => {
    dispatch(setOnFetch(true));
    const sheet = (await fetchSheet()).data;
    dispatch(setInfo(sheet));
    dispatch(setOnFetch(false));
  };
};
