import 'katex/dist/katex.min.css';
import katex from 'katex';

export const htmlToLatex = (string: any) => {
  const pattern = new RegExp(/\${2}(.*?)\${2}/, 'g');
  if (string === null || string === undefined) {
    string = String.raw(string);
  }
  string = string.replace(new RegExp(/<(?:.|\n)*?>/, 'gm'), '').replace(new RegExp(/(?:\r\n|\r|\n)/, 'gm'), '<br>');
  let html = string.replace(pattern, (e: { split: (arg0: RegExp) => any[]; }) => {
    let string = e.split(new RegExp(/\$\$/))[1];
    return string ?
      katex.renderToString(string, {
        throwOnError: false,
        displayMode: true
      }) :
      '';
  });
  const pattern2 = new RegExp(/\${1}(?!\s)(.*?)(?!\s)\${1}/, 'g');
  html = html.replace(pattern2, (e: { split: (arg0: RegExp) => any[]; }) => {
    let string = e.split(new RegExp(/\$/))[1];
    return string ?
      katex.renderToString(string, {
        throwOnError: false
      }) :
      '';
  });
  return html;
};