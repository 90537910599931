import React, { useRef, useState } from 'react';
import styles from './UploadSheetDialog.module.scss';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { uploadSheet } from '../services/sheet';

interface UploadSheetDialogProps {
  sheetId: string;
  show: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => Promise<void>;
  onFetch: React.Dispatch<React.SetStateAction<boolean>>;
  progress: (progressEvent: ProgressEvent) => void;
}

export default (props: UploadSheetDialogProps) => {
  const { sheetId, show, close, fetch, onFetch, progress } = props;
  const inputBox = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<File[]>([]);
  const fileReg = /[PCMEBS][KJHNUE]-\w{1,4}\d{2}(\w+)/;

  const handleFile = (files: FileList | null) => {
    if (files) {
      const res = [];
      for (let i = 0; i < files.length; i++) {
        const fileName = files[i].name;
        const fileType = fileName.slice(fileName.lastIndexOf('.') + 1);
        if (
          files[i].type === 'application/pdf' ||
          fileType === 'docx' ||
          files[i].type === 'video/mp4'
        ) {
          if (fileReg.test(fileName)) {
            const exec = fileReg.exec(fileName);
            if (exec && exec[1]) {
              const fileSpec = exec[1][0] === 'T' ? exec[1].slice(1) : exec[1];
              if (
                fileSpec === 'LECTURE' ||
                fileSpec === 'VDO' ||
                fileSpec === 'EXAMPLE' ||
                fileSpec === 'EXERCISE' ||
                fileSpec === 'REVIEW' ||
                fileSpec === 'EXAMPLEKEY' ||
                fileSpec === 'EXERCISEKEY' ||
                fileSpec === 'REVIEWKEY'
              ) {
                res.push(files[i]);
              }
            }
          }
        }
      }
      setFileList(res);
    }
  };

  const upload = () => {
    if (window.confirm('Upload files?')) {
      onFetch(true);
      const form = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        const fileName = fileList[i].name;
        const fileType = fileName
          .slice(fileName.lastIndexOf('.') + 1)
          .toUpperCase();
        const exec = fileReg.exec(fileName);
        if (exec && exec[1]) {
          const fileSpec =
            exec[1][0] === 'T'
              ? exec[1].toLowerCase().slice(1)
              : exec[1].toLowerCase();
          if (fileList[i].type === 'application/pdf') {
            if (fileSpec.slice(-3) === 'key') {
              form.append('files', fileList[i], `${fileSpec.slice(0, -3)}1key`);
            } else {
              form.append('files', fileList[i], `${fileSpec}Pdf1`);
            }
          } else if (fileType === 'DOCX') {
            form.append('files', fileList[i], `${fileSpec}Word1`);
          } else if (fileList[i].type === 'video/mp4') {
            form.append('files', fileList[i], 'video1');
          }
        }
      }
      uploadSheet(sheetId, form, progress)
        .then(() => {
          fetch();
          onFetch(false);
          close(false);
        })
        .catch(err => {
          onFetch(false);
          close(false);
        });
    }
  };

  const filesList = () => {
    if (fileList.length > 0) {
      return fileList.map(e => <p key={`filelist${e.name}`}>{e.name}</p>);
    } else {
      return (
        <CloudUpload
          className={styles.cloudIcon}
          onClick={() => {
            if (inputBox && inputBox.current) {
              inputBox.current.click();
            }
          }}
          onDragOver={e => e.preventDefault()}
        />
      );
    }
  };

  return (
    <Dialog open={show} fullWidth onClose={() => close(false)}>
      <DialogTitle>Upload sheet</DialogTitle>
      <DialogContent>
        <div
          className={styles.uploadSpace}
          onDragOver={e => e.preventDefault()}
          onDrop={e => {
            e.preventDefault();
            handleFile(e.dataTransfer.files);
          }}
        >
          {filesList()}
        </div>
        <input
          ref={inputBox}
          type="file"
          placeholder="upload"
          multiple
          style={{ display: 'none' }}
          onChange={e => handleFile(e.target.files)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={upload}
          disabled={fileList.length === 0}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
