import React from 'react';
import { connect } from 'react-redux';
import Autocomplete, { Option } from './Autocomplete';
import { AppState } from '../state/ducks';
import { Sheet } from '../state/ducks/sheet/types';

const mapStateToProps = (state: AppState) => ({
  sheet: state.sheet,
});

interface SelectSheetProps {
  sheet: Sheet;
  value: Option | null;
  onChange: (nextValue: Option | null) => any;
}

export default connect(mapStateToProps)((props: SelectSheetProps) => {
  const { sheet, value, onChange } = props;
  const options: Option[] = sheet.info.map(e => ({
    label: `${e.code}`,
    value: e._id,
  }));
  return (
    <>
      <h1>Select Sheet</h1>
      <Autocomplete
        placeholder="Sheet"
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
});
