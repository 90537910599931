import React, { Component } from 'react';
import './App.scss';
import { Switch } from 'react-router';
import routes from './routes';
import firebase from 'firebase/app';
import 'firebase/analytics';

export default () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  return <Switch>{routes}</Switch>;
};
