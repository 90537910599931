import React, { useEffect, useState } from 'react';
import styles from './PlanManagement.module.scss';
import { History } from 'history';
import { getPlanById, SheetInPlan, updatePlan } from '../services/plan';
import MainContainer from '../components/MainContainer';
import { fetchSheet } from '../services/sheet';
import { SheetInfo } from '../state/ducks/sheet/types';
import PlanManagementBox from '../components/PlanManagementBox';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';

interface PlanManagementProps {
  history: History;
  match: { params: { planId: string } };
}

export default (props: PlanManagementProps) => {
  const planId = props.match.params.planId;
  const [allSheets, setAllSheets] = useState<SheetInfo[]>([]);
  const [planName, setPlanName] = useState('');
  const [sheetInPlan, setSheetInPlan] = useState<SheetInPlan[]>([]);
  const [edited, setEdited] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [suggest, setSuggest] = useState<SheetInPlan[]>([]);
  const [sheetOnDrag, setSheetOndrag] = useState('');

  const fetch = async () => {
    try {
      const sheets = (await fetchSheet()).data;
      const sheetReg = /[PCMEBS][KJHNUE]-\w{1,4}\d{2}[T]?\((\d+)\.\d+\.\d+\)/;
      const sorted = sheets.sort((a, b) => {
        const execA = sheetReg.exec(a.code);
        const execB = sheetReg.exec(b.code);
        if (execA && execB) {
          return Number(execB[1]) - Number(execA[1]);
        } else {
          return -1;
        }
      });
      setAllSheets(sorted);
    } catch (error) {
      // empty
    }
  };
  const fetchPlan = async () => {
    try {
      const plan = (await getPlanById(planId)).data;
      setPlanName(plan.name);
      setSheetInPlan(plan.sheetIds);
      setEdited(false);
    } catch (error) {
      // empty
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSuggest([]);
  };
  const genSuggestion = (ev: React.ChangeEvent<any>) => {
    let value: string = ev.target.value;
    value = value.toUpperCase();
    let counter = 0;
    const suggestion = [];
    for (const i of allSheets) {
      if (i.code.indexOf(value) >= 0) {
        counter += 1;
        suggestion.push({
          _id: i._id,
          code: i.code,
        });
      }
      if (counter >= 72) {
        break;
      }
    }
    setSuggest(
      suggestion.sort((a, b) => {
        const sheetReg = /[PCMEBS][KJHNUE]-\w{1,4}\d{2}[T]?\((\d+)\.\d+\.\d+\)/;
        const execA = sheetReg.exec(a.code);
        const execB = sheetReg.exec(b.code);
        if (execA && execB) {
          if (Number(execA[1]) === Number(execB[1])) {
            if (a.code > b.code) {
              return 1;
            } else {
              return -1;
            }
          } else {
            return Number(execB[1]) - Number(execA[1]);
          }
        } else {
          return -1;
        }
      })
    );
  };
  const addSheet = (sheetId: string, sheetCode: string) => {
    if (!edited) {
      setEdited(true);
    }
    const newSheet = { _id: sheetId, code: sheetCode };
    const sheetArr = sheetInPlan;
    sheetArr.push(newSheet);
    setSheetInPlan(sheetArr);
  };
  const swapSheet = (targetId: string) => {
    if (!edited) {
      setEdited(true);
    }
    const sheetArr = sheetInPlan;
    const dragIndex = sheetArr.findIndex(e => e._id === sheetOnDrag);
    const dropIndex = sheetArr.findIndex(e => e._id === targetId);
    if (dragIndex !== dropIndex) {
      const temp = sheetArr[dragIndex];
      if (dragIndex < dropIndex) {
        for (let i = dragIndex; i < dropIndex; i++) {
          sheetArr[i] = sheetArr[i + 1];
        }
      } else {
        for (let i = dragIndex; i > dropIndex; i--) {
          sheetArr[i] = sheetArr[i - 1];
        }
      }
      sheetArr[dropIndex] = temp;
    }
    setSheetInPlan(sheetArr);
  };
  const remove = (sheetId: string) => {
    if (window.confirm('Are you sure?')) {
      if (!edited) {
        setEdited(true);
      }
      let sheetArr = sheetInPlan;
      sheetArr = sheetArr.filter(el => el._id !== sheetId);
      setSheetInPlan(sheetArr);
    }
  };
  const update = async () => {
    if (edited && window.confirm('Are you sure?')) {
      const data = sheetInPlan.map(e => e._id);
      try {
        await updatePlan(planId, { sheetIds: data });
        fetchPlan();
      } catch (error) {
        window.alert('Something went wrong, try again.');
      }
    }
  };

  useEffect(() => {
    fetch();
    fetchPlan();
  }, [planId]);

  return (
    <MainContainer>
      <div className={styles.container}>
        <div className={styles.title}>
          <div className={styles.box} onClick={() => setOpenDialog(true)}>
            +
          </div>
          <p>{planName}</p>
        </div>
        <div className={styles.overflowBox}>
          {sheetInPlan.map((el, index) => (
            <PlanManagementBox
              key={`planMB_${index}`}
              sheetPlan={el}
              setDragSheet={setSheetOndrag}
              swapSheet={swapSheet}
              remove={remove}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <Button variant="contained" disabled={!edited} onClick={update}>
            SAVE
          </Button>
        </div>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen>
        <DialogTitle>Add new sheet</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Sheet Code"
            placeholder="Input sheet code"
            onChange={genSuggestion}
          />
          <div className={styles.suggestion}>
            {suggest.map((el, index) => {
              const sheetReg = /[PCMEBS][KJHNUE]-\w{1,4}\d{2}([T]?)\(\d+\.\d+\.\d+\)/;
              const exec = sheetReg.exec(el.code);
              let isTest = false;
              if (exec && exec[1] === 'T') {
                isTest = true;
              }
              return (
                <div
                  key={`suggest_${index}`}
                  className={`${styles.suggestBox} ${
                    isTest ? styles.testBox : ''
                  }`}
                  onClick={() => addSheet(el._id, el.code)}
                >
                  {el.code}
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
};
