import { createSheet, uploadSheet } from "../../services/sheet";
import { Subject, SheetClass, SheetInfoType, SheetInfo } from "../../state/ducks/sheet/types";
import jsPDF from 'jspdf';
import coverTemplate from '../../assets/sheet-template/cover_template.jpeg';
import testCoverTemplate from '../../assets/sheet-template/test_cover_template.jpeg';
import sheetTemplate from '../../assets/sheet-template/sheet_template.jpeg';
import { PDFDocumentProxy, getDocument } from "pdfjs-dist";
import { htmlToLatex } from './GenerateLatex';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';

export const migrateBranchSheet = async (
  type: string,
  sheetCode: string,
  sheetData: any,
  previewDiv: HTMLDivElement,
  font: string,
  imageWidth: string,
  imageAlign: string,
  onlyPreview: boolean
) => {
  while (previewDiv.firstChild) {
    previewDiv.removeChild(previewDiv.firstChild);
  }
  const sheetReg = /([PCMEBS])([KJHNUE])-(\w{1,3})(\w{1})(\d{2})([T]?)\((\d+)\.\d+\.\d+\)/;
  if (sheetReg.test(sheetCode)) {
    const execData = sheetReg.exec(sheetCode);
    if (execData) {
      let sheetSubject: Subject;
      let sheetClass: SheetClass;
      let sheetType = SheetInfoType.SHEET;
      switch (execData[1]) {
        case 'M':
          sheetSubject = Subject.MATH;
          break;
        case 'P':
          sheetSubject = Subject.PHYSICS;
          break;
        case 'C':
          sheetSubject = Subject.CHEMISTRY;
          break;
        case 'E':
          sheetSubject = Subject.ENGLISH;
          break;
        default:
          sheetSubject = Subject.MATH;
          break;
      }
      switch (execData[1]) {
        case 'J':
          sheetClass = SheetClass.JUNIOR;
          break;
        case 'K':
          sheetClass = SheetClass.KIDS;
          break;
        case 'N':
          sheetClass = SheetClass.NURSERY;
          break;
        case 'U':
          sheetClass = SheetClass.UNIVERSITY;
          break;
        case 'H':
          sheetClass = SheetClass.HIGH;
          break;
        default:
          sheetClass = SheetClass.JUNIOR;
          break;
      }
      if (execData[6] !== '') {
        sheetType = SheetInfoType.TEST;
      }
      if (onlyPreview) {
        if (type === 'sheet') {
          migrateSheet(sheetCode, '', sheetData, previewDiv, font, imageWidth, imageAlign, true);
        } else {
          migrateTest(sheetCode, '', sheetData, previewDiv, font, imageWidth, imageAlign, true);
        }
      } else {
        try {
          const sheetInfo = (await createSheet(
            execData[0],
            sheetData.title,
            sheetSubject,
            sheetClass,
            Number(execData[5]),
            sheetType
          )).data;
          migrateCover(type, sheetInfo, sheetData);
          if (type === 'sheet') {
            migrateSheet(sheetCode, sheetInfo, sheetData, previewDiv, font, imageWidth, imageAlign);
          } else {
            migrateTest(sheetCode, sheetInfo, sheetData, previewDiv, font, imageWidth, imageAlign);
          }
        } catch (error) {
          // empty
        }
      }
    }
  }
}

const asyncGetImg = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  })
}

const migrateCover = async (
  type: string, sheetInfo: SheetInfo, sheetData: any
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let template: HTMLImageElement;
  switch (type) {
    case 'sheet':
      template = await asyncGetImg(coverTemplate) as HTMLImageElement;
      break;
    case 'checkup':
      template = await asyncGetImg(testCoverTemplate) as HTMLImageElement;
      break;
    case 'test':
      template = await asyncGetImg(testCoverTemplate) as HTMLImageElement;
      break;
    default:
      template = await asyncGetImg(coverTemplate) as HTMLImageElement;
      break;
  }
  canvas.width = template.width;
  canvas.height = template.height;
  const newSheetReg = /([PCMEBS][KJHNUE]-\w{1,4}\d{2}[T]?)/;
  const execName = newSheetReg.exec(sheetInfo.code);
  if (ctx && execName) {
    ctx.drawImage(template, 0, 0);
    ctx.textAlign = 'center';
    ctx.font = '300px Cordia New';
    ctx.fillStyle = '#BDBDBD';
    ctx.fillText(execName[0], 826, 750);
    if (sheetData.title.length > 20) {
      ctx.font = '80px Cordia New';
    } else {
      ctx.font = '120px Cordia New';
    }
    ctx.fillText(sheetData.title, 826, 950);
  }
  const doc = new jsPDF('p', 'mm', 'a4', true);
  const myImg = canvas.toDataURL('image/png');
  doc.addImage(myImg, 'PNG', 0, 0, 210, 297, '', 'MEDIUM');
  switch (type) {
    case 'sheet':
      migrateLecture(doc, sheetInfo, sheetData);
      break;
    default:
      const form = new FormData();
      form.append('files', doc.output('blob'), 'lecturePdf1');
      uploadSheet(sheetInfo._id, form).then(() => {
        console.log('migrate lecture complete');
      }).catch(err => {
        // empty
      });
      break;
  }
}

const migrateLecture = async (
  doc: jsPDF, sheetInfo: SheetInfo, sheetData: any
) => {
  const pdfUrl = sheetData.lectureUrl;
  const pdf: PDFDocumentProxy = await getDocument(pdfUrl).promise;
  const numPages = pdf.numPages;
  doc.addPage();
  for (let i = 1; i <= numPages; i++) {
    const pdfPage = await pdf.getPage(i);
    const viewport = pdfPage.getViewport(3);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      await pdfPage.render(renderContext).promise;
      const myImg = canvas.toDataURL('image/png');
      doc.addPage();
      doc.addImage(myImg, 'PNG', 0, 0, 210, 297, '', 'MEDIUM');
    }
  }
  const form = new FormData();
  form.append('files', doc.output('blob'), 'lecturePdf1');
  uploadSheet(sheetInfo._id, form).then(() => {
    console.log('migrate lecture complete');
  }).catch(err => {
    // empty
  });
}

const migrateSheet = async (
  sheetCode: string,
  sheetInfo: SheetInfo | string,
  sheetData: any,
  previewDiv: HTMLDivElement,
  fontSize: string,
  imageWidth: string,
  imageAlign: string,
  isPreview = false
) => {
  const doc = new jsPDF('p', 'mm', 'a4', true);
  let isFirstPage = true;
  if (isPreview) {
    const sheet = sheetData.sheets[0];
    const page = sheet.pages[0];
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const template = await asyncGetImg(sheetTemplate) as HTMLImageElement;
    canvas.width = template.width;
    canvas.height = template.height;
    const div = await generateQuestionDiv(page.row, page.column, page.description, page.questions, fontSize, imageWidth, imageAlign);
    if (ctx) {
      ctx.drawImage(template, 0, 0);
      ctx.textAlign = 'center';
      ctx.fillStyle = 'white';
      ctx.font = '24px Tahoma';
      ctx.fillText('1/99', 1595, 95);
      ctx.textAlign = 'left';
      ctx.fillStyle = 'black';
      ctx.fillText(`${sheet.type}:${sheetData.title} - ${sheetCode}`, 65, 2230);
      previewDiv.style.width = `${canvas.width}px`;
      previewDiv.style.height = `${canvas.height}px`;
      previewDiv.appendChild(canvas);
      previewDiv.appendChild(div);
    }
  } else {
    for (const sheet of sheetData.sheets) {
      let pageIndex = 1;
      const pageCount = sheet.pages.length;
      for (const page of sheet.pages) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const template = await asyncGetImg(sheetTemplate) as HTMLImageElement;
        canvas.width = template.width;
        canvas.height = template.height;
        const qr = document.createElement('canvas');
        if (typeof sheetInfo !== 'string') {
          await QRCode.toCanvas(
            qr,
            `https://fhb.monkeyatc.com/qrscan/${sheetInfo._id}/exercise1key`,
            { errorCorrectionLevel: 'M', width: 100 }
          )
        }
        const div = await generateQuestionDiv(page.row, page.column, page.description, page.questions, fontSize, imageWidth, imageAlign);
        if (ctx) {
          ctx.drawImage(template, 0, 0);
          ctx.drawImage(qr, 1417, 17);
          ctx.textAlign = 'center';
          ctx.fillStyle = 'white';
          ctx.font = '24px Tahoma';
          ctx.fillText(`${pageIndex}/${pageCount}`, 1595, 95);
          ctx.textAlign = 'left';
          ctx.fillStyle = 'black';
          ctx.fillText(`${sheet.type}:${sheetData.title} - ${sheetCode}`, 65, 2230);
          previewDiv.style.width = `${canvas.width}px`;
          previewDiv.style.height = `${canvas.height}px`;
          previewDiv.appendChild(canvas);
          previewDiv.appendChild(div);
          const myfile = await html2canvas(previewDiv, { allowTaint: false, useCORS: true, scale: 1 });
          const myImg = myfile.toDataURL('image/png');
          if (!isFirstPage) {
            doc.addPage();
          } else {
            isFirstPage = false;
          }
          doc.addImage(myImg, 'PNG', 0, 0, 210, 297, '', 'MEDIUM');
          previewDiv.removeChild(canvas);
          previewDiv.removeChild(div);
          if (pageIndex === pageCount && pageCount % 2 !== 0) {
            doc.addPage();
          }
          pageIndex++;
        }
      }
    }
    const form = new FormData();
    form.append('files', doc.output('blob'), 'exercisePdf1');
    if (typeof sheetInfo !== 'string') {
      uploadSheet(sheetInfo._id, form).then(() => {
        console.log('migrate exercise complete');
        migrtateAnswers('sheet', sheetInfo, sheetData, previewDiv);
      }).catch(err => {
        // empty
      });
    }
  }
}

const migrateTest = async (
  sheetCode: string,
  sheetInfo: SheetInfo | string,
  sheetData: any,
  previewDiv: HTMLDivElement,
  fontSize: string,
  imageWidth: string,
  imageAlign: string,
  isPreview = false
) => {
  const doc = new jsPDF('p', 'mm', 'a4', true);
  let pageCount = sheetData.sheets.length;
  let pageIndex = 1;
  if (isPreview) {
    const page = sheetData.sheets[0];
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const template = await asyncGetImg(sheetTemplate) as HTMLImageElement;
    canvas.width = template.width;
    canvas.height = template.height;
    const div = await generateQuestionDiv(page.row, page.column, page.description, page.questions, fontSize, imageWidth, imageAlign);
    if (ctx) {
      ctx.drawImage(template, 0, 0);
      ctx.textAlign = 'center';
      ctx.fillStyle = 'white';
      ctx.font = '24px Tahoma';
      ctx.fillText(`${pageIndex}/${pageCount}`, 1595, 95);
      ctx.textAlign = 'left';
      ctx.fillStyle = 'black';
      ctx.fillText(`TEST:${sheetData.title} - ${sheetCode}`, 65, 2230);
      previewDiv.style.width = `${canvas.width}px`;
      previewDiv.style.height = `${canvas.height}px`;
      previewDiv.appendChild(canvas);
      previewDiv.appendChild(div);
    }
  } else {
    for (const page of sheetData.sheets) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const template = await asyncGetImg(sheetTemplate) as HTMLImageElement;
      canvas.width = template.width;
      canvas.height = template.height;
      const qr = document.createElement('canvas');
      if (typeof sheetInfo !== 'string') {
        await QRCode.toCanvas(
          qr,
          `https://fhb.monkeyatc.com/qrscan/${sheetInfo._id}/exercise1key`,
          { errorCorrectionLevel: 'M', width: 100 }
        )
      }
      const div = await generateQuestionDiv(page.row, page.column, page.description, page.questions, fontSize, imageWidth, imageAlign);
      if (ctx) {
        ctx.drawImage(template, 0, 0);
        ctx.drawImage(qr, 1417, 17);
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.font = '24px Tahoma';
        ctx.fillText(`${pageIndex}/${pageCount}`, 1595, 95);
        ctx.textAlign = 'left';
        ctx.fillStyle = 'black';
        ctx.fillText(`TEST:${sheetData.title} - ${sheetCode}`, 65, 2230);
        previewDiv.style.width = `${canvas.width}px`;
        previewDiv.style.height = `${canvas.height}px`;
        previewDiv.appendChild(canvas);
        previewDiv.appendChild(div);
        const myfile = await html2canvas(previewDiv, { allowTaint: false, useCORS: true, scale: 1 });
        const myImg = myfile.toDataURL('image/png');
        if (pageIndex !== 1) {
          doc.addPage();
        }
        doc.addImage(myImg, 'PNG', 0, 0, 210, 297, '', 'MEDIUM');
        previewDiv.removeChild(canvas);
        previewDiv.removeChild(div);
        pageIndex++;
      }
    }
    const form = new FormData();
    form.append('files', doc.output('blob'), 'exercisePdf1');
    if (typeof sheetInfo !== 'string') {
      uploadSheet(sheetInfo._id, form).then(() => {
        console.log('migrate exercise complete');
        migrtateAnswers('test', sheetInfo, sheetData, previewDiv);
      }).catch(err => {
        // empty
      });
    }
  }
}

const generateQuestionDiv = async (
  row: number, col: number, description: string, questions: any, fontSize: string, imageWidth: string, imageAlign: string
) => {
  const parent = document.createElement('div');
  const title = document.createElement('div');
  const grid = document.createElement('div');
  parent.style.position = 'absolute';
  parent.style.top = '110px';
  parent.style.left = '71px';
  parent.style.width = `1510px`;
  parent.style.height = `2050px`;
  title.style.width = '100%';
  title.style.height = '100px';
  title.style.display = 'flex';
  title.style.alignItems = 'center';
  title.innerHTML = description;
  title.style.fontWeight = '600';
  title.style.fontSize = '28px';
  grid.style.display = 'grid';
  grid.style.gridGap = '2px';
  grid.style.padding = '2px';
  grid.style.width = '100%';
  grid.style.height = '1950px';
  grid.style.backgroundColor = 'black';
  grid.style.gridTemplateRows = `repeat(${row},1fr)`;
  grid.style.gridTemplateColumns = `repeat(${col},1fr)`;
  let index = 1;
  for (const i of questions) {
    const question = document.createElement('div');
    const text = document.createElement('div');
    question.style.backgroundColor = 'white';
    question.style.padding = '12px';
    text.style.width = '100%';
    text.innerHTML = `${index}. ${htmlToLatex(i.question)}`;
    text.style.fontSize = `${fontSize}px`;
    text.style.marginBottom = '18px';
    question.appendChild(text);
    if (i.questionImageUrl) {
      const img = await asyncGetImg(i.questionImageUrl) as HTMLImageElement;
      img.style.maxWidth = `${imageWidth}%`;
      img.style.width = `${imageWidth}%`;
      if (imageAlign === '2') {
        img.style.marginLeft = `${(100 - Number(imageWidth)) / 2}%`;
      } else if (imageAlign === '3') {
        img.style.marginLeft = `${100 - Number(imageWidth)}%`;
      } else {
        img.style.marginLeft = '0%';
      }
      question.appendChild(img);
    }
    if (i.choice && i.choice !== 'null') {
      question.appendChild(generateQuestionChoice(JSON.parse(i.choice), i.choiceFormat, fontSize));
    }
    grid.appendChild(question);
    index++;
  }
  parent.appendChild(title);
  parent.appendChild(grid);
  return parent;
}

const generateQuestionChoice = (choice: any, choiceFormat: string, fontSize: string) => {
  const abcd = ['A', 'B', 'C', 'D'];
  let index = 0;
  const choiceBox = document.createElement('div');
  choiceBox.style.width = '100%';
  choiceBox.style.display = 'flex';
  choiceBox.style.flexFlow = 'column nowrap';
  for (const i of choice) {
    if (i && i !== '') {
      const myChoice = document.createElement('div');
      myChoice.innerHTML = `${abcd[index % 4]}. ${htmlToLatex(i)}`;
      myChoice.style.display = 'flex';
      myChoice.style.fontSize = `${fontSize}px`;
      myChoice.style.alignItems = 'center';
      myChoice.style.padding = '12px';
      if (choiceFormat !== 'VERTICAL') {
        myChoice.style.justifyContent = 'center';
        myChoice.style.flex = '1';
      }
      choiceBox.appendChild(myChoice);
      index++;
    }
  }
  if (choiceFormat !== 'VERTICAL') {
    choiceBox.style.flexFlow = 'row nowrap';
  }
  return choiceBox;
}

const migrtateAnswers = async (
  type: string, sheetInfo: SheetInfo | string, sheetData: any, previewDiv: HTMLDivElement
) => {
  const doc = new jsPDF('p', 'mm', 'a4', true);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const template = await asyncGetImg(sheetTemplate) as HTMLImageElement;
  let isFirstPage = true;
  canvas.width = template.width;
  canvas.height = template.height;
  if (type === 'sheet') {
    for (const sheet of sheetData.sheets) {
      let pageIndex = 1;
      const pageCount = sheet.pages.length;
      for (const page of sheet.pages) {
        const div = generateAnswers(page.row, page.column, page.questions);
        if (ctx) {
          ctx.drawImage(template, 0, 0);
          previewDiv.style.width = `${canvas.width}px`;
          previewDiv.style.height = `${canvas.height}px`;
          ctx.textAlign = 'center';
          ctx.fillStyle = 'white';
          ctx.font = '24px Tahoma';
          ctx.fillText(`${pageIndex}/${pageCount}`, 1595, 95);
          previewDiv.appendChild(canvas);
          previewDiv.appendChild(div);
          const myfile = await html2canvas(previewDiv, { allowTaint: false, useCORS: true, scale: 1 });
          const myImg = myfile.toDataURL('image/png');
          if (isFirstPage) {
            isFirstPage = false;
          } else {
            doc.addPage();
          }
          doc.addImage(myImg, 'PNG', 0, 0, 210, 297, '', 'MEDIUM');
          previewDiv.removeChild(canvas);
          previewDiv.removeChild(div);
          pageIndex++;
        }
      }
    }
  } else {
    let pageIndex = 1;
    const pageCount = sheetData.sheets.length;
    for (const page of sheetData.sheets) {
      const div = generateAnswers(page.row, page.column, page.questions);
      if (ctx) {
        ctx.drawImage(template, 0, 0);
        previewDiv.style.width = `${canvas.width}px`;
        previewDiv.style.height = `${canvas.height}px`;
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.font = '24px Tahoma';
        ctx.fillText(`${pageIndex}/${pageCount}`, 1595, 95);
        previewDiv.appendChild(canvas);
        previewDiv.appendChild(div);
        const myfile = await html2canvas(previewDiv, { allowTaint: false, useCORS: true, scale: 1 });
        const myImg = myfile.toDataURL('image/png');
        if (isFirstPage) {
          isFirstPage = false;
        } else {
          doc.addPage();
        }
        doc.addImage(myImg, 'PNG', 0, 0, 210, 297, '', 'MEDIUM');
        previewDiv.removeChild(canvas);
        previewDiv.removeChild(div);
        pageIndex++;
      }
    }
  }
  const form = new FormData();
  form.append('files', doc.output('blob'), 'exercise1key');
  if (typeof sheetInfo !== 'string') {
    uploadSheet(sheetInfo._id, form).then(() => {
      console.log('migrate answer complete');
      window.alert('Complete!!!');
    }).catch(err => {
      // empty
    });
  }
}

const generateAnswers = (row: number, col: number, questions: any) => {
  const parent = document.createElement('div');
  const grid = document.createElement('div');
  parent.style.position = 'absolute';
  parent.style.top = '110px';
  parent.style.left = '71px';
  parent.style.width = `1510px`;
  parent.style.height = `2050px`;
  grid.style.display = 'grid';
  grid.style.gridGap = '2px';
  grid.style.padding = '2px';
  grid.style.width = '100%';
  grid.style.height = '1950px';
  grid.style.backgroundColor = 'black';
  grid.style.gridTemplateRows = `repeat(${row},1fr)`;
  grid.style.gridTemplateColumns = `repeat(${col},1fr)`;
  let index = 1;
  for (const i of questions) {
    const question = document.createElement('div');
    const text = document.createElement('div');
    question.style.backgroundColor = 'white';
    question.style.padding = '12px';
    text.style.width = '100%';
    text.innerHTML = `${index}. ${htmlToLatex(i.answer)}`;
    text.style.fontSize = `24px`;
    text.style.marginBottom = '18px';
    question.appendChild(text);
    grid.appendChild(question);
    index++;
  }
  parent.appendChild(grid);
  return parent;
}