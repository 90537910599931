import gql from 'graphql-tag';

export interface ILessonDetailFromCode {
  version: number;
  division: string;
  level: string;
  type: string;
  order: number;
}

export function decodeLessonCode(code: string): ILessonDetailFromCode {
  const [lesson, level, division, version] = code
    .trim()
    .split('-')
    .reverse();
  const detail = lesson.split(/(C|T)/);
  const result = {
    version: Number((version || 'v0').substr(1)),
    division,
    level,
    type: 'L',
    order: 0,
  };
  if (detail.length === 1) {
    result.order = Number(detail[0]);
  } else {
    result.type = detail[1];
    result.order = Number(detail[2]);
  }
  return result;
}

export const lessonQuery = gql`
  query($division: String!, $level: String!, $order: Int!) {
    lessons(
      where: {
        order: $order
        level: { code: $level, division: { code: $division } }
      }
    ) {
      title
      lecture
      lectureUrl
      video
      videoUrl
      sheets {
        description
        type
        order
        version
        pages {
          order
          description
          row
          column
          questions {
            order
            question
            answer
            choice
            choiceFormat
            questionImage
            questionImageUrl
          }
        }
      }
    }
  }
`;

export const checkupQuery = gql`
  query($division: String!, $level: String!, $order: Int!) {
    checkups(
      where: {
        order: $order
        level: { code: $level, division: { code: $division } }
      }
    ) {
      title
      description
      version
      sheets {
        row
        column
        description
        type
        order
        questions {
          order
          question
          answer
          choice
          choiceFormat
          questionImage
          questionImageUrl
        }
      }
    }
  }
`;

export const testQuery = gql`
  query($division: String!, $level: String!, $order: Int!) {
    tests(
      where: {
        order: $order
        level: { code: $level, division: { code: $division } }
      }
    ) {
      title
      description
      version
      sheets {
        row
        column
        description
        type
        order
        questions {
          order
          question
          answer
          choice
          choiceFormat
          questionImage
          questionImageUrl
        }
      }
    }
  }
`;
