import React, { useState } from 'react';
import styles from './SheetList.module.scss';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { SheetInfo, SheetInfoType } from '../state/ducks/sheet/types';
import { deleteSheet, editSheetInfo } from '../services/sheet';

interface SheetListProps {
  name: string;
  sheets: SheetInfo[];
  getSheets: () => Promise<void>;
  editSheets: (info: SheetInfo) => void;
  sheetInfo: (sheetId: string) => void;
  editStatus: (event: any, sheetId: string) => void;
  expanded: string;
  expandedPanelChange: (val: string) => void;
}

export default (props: SheetListProps) => {
  const {
    name,
    sheets,
    getSheets,
    editSheets,
    sheetInfo,
    editStatus,
    expanded,
    expandedPanelChange,
  } = props;
  const [dragged, setDragged] = useState('');
  const [dragOver, setDragOver] = useState('');
  const [reArrange, setReArrange] = useState(false);

  const delSheet = (sheetCode: string, sheetId: string) => {
    if (window.confirm(`Delete ${sheetCode}?`)) {
      deleteSheet(sheetId)
        .then(() => {
          getSheets();
        })
        .catch(err => {
          alert('Error, try again.');
        });
    }
  };

  const setSequence = () => {
    if (dragOver !== dragged) {
      setReArrange(true);
      const dragIndex = sheets.findIndex(e => e._id === dragged);
      const dropIndex = sheets.findIndex(e => e._id === dragOver);
      if (dragIndex !== dropIndex) {
        const temp = sheets[dragIndex];
        if (dragIndex < dropIndex) {
          for (let i = dragIndex; i < dropIndex; i++) {
            sheets[i] = sheets[i + 1];
          }
        } else {
          for (let i = dragIndex; i > dropIndex; i--) {
            sheets[i] = sheets[i - 1];
          }
        }
        sheets[dropIndex] = temp;
      }
      const promise = sheets.map((e, i) =>
        editSheetInfo(e._id, { order: i + 1 })
      );
      Promise.all(promise)
        .then(() => {
          getSheets();
          setReArrange(false);
        })
        .catch(err => {
          alert('Error, try again.');
          setReArrange(false);
        });
    }
  };

  const generateSheet = () => {
    return sheets.map(e => {
      return (
        <div
          draggable
          key={`sheet_${e._id}`}
          className={
            e.type === SheetInfoType.SHEET ? styles.sheet : styles.sheetTest
          }
          onDrag={() => setDragged(e._id)}
          onDragOver={ev => {
            ev.preventDefault();
            setDragOver(e._id);
          }}
          onDrop={ev => {
            ev.preventDefault();
            setSequence();
          }}
        >
          <div className={styles.sheetTitle}>
            <div
              className={`${styles.indicator} ${styles[e.status]} ${
                styles.pointer
              }`}
              onClick={event => editStatus(event, e._id)}
            />
            <p
              className={`${styles.sheetCode} ${styles.pointer}`}
              onClick={() => sheetInfo(e._id)}
            >
              {e.code}
            </p>
            <div />
            <Edit
              className={`${styles.editBtn} ${styles.pointer}`}
              onClick={() => editSheets(e)}
            />
            <DeleteForever
              className={`${styles.trashBtn} ${styles.pointer}`}
              onClick={() => delSheet(e.code, e._id)}
            />
          </div>
          <p className={styles.sheetDescription}>{e.title}</p>
        </div>
      );
    });
  };

  const generateIndicatorBox = () => {
    let counter = 0;
    let prev = 0;
    return sheets.map(e => {
      prev = counter;
      if (e.type === SheetInfoType.SHEET) {
        counter++;
        return (
          <React.Fragment key={`indicatorbox_${e._id}`}>
            {prev % 5 === 0 && prev !== 0 ? (
              <div className={styles.spliter} />
            ) : null}
            <div className={`${styles.indicatorBox} ${styles[e.status]}`} />
          </React.Fragment>
        );
      } else {
        return (
          <div
            key={`indicatorbox_${e._id}`}
            className={styles.indicatorTestBox}
          >
            <div className={styles[e.status]}>T</div>
          </div>
        );
      }
    });
  };

  const generateSheetCount = () => {
    const sheetCount = sheets.reduce((sum, curr) => {
      if (curr.type === SheetInfoType.SHEET) {
        return (sum += 1);
      } else {
        return sum;
      }
    }, 0);
    const testCount = sheets.reduce((sum, curr) => {
      if (curr.type === SheetInfoType.TEST) {
        return (sum += 1);
      } else {
        return sum;
      }
    }, 0);
    return (
      <p className={styles.sheetCode}>
        {sheetCount}+<span style={{ color: '#2c78bf' }}>{testCount}</span>
      </p>
    );
  };

  return (
    <ExpansionPanel
      expanded={expanded === name}
      onChange={() => expandedPanelChange(name)}
      style={{
        backgroundColor:
          expanded === name || expanded === '' ? 'white' : '#e1e2e3',
      }}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
      >
        <div className={styles.summaryBox}>
          <div className={styles.titleBox}>
            <p className={styles.sheetCode}>
              {name.slice(0, -2)}
              <span style={{ color: '#BDBDBD' }}>{name.slice(-2)}</span>
            </p>
            {generateSheetCount()}
          </div>
          <div className={styles.indicatorGrid}>{generateIndicatorBox()}</div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={styles.sheetContainer}>
          {reArrange ? (
            <div className={styles.fullLoader}>
              <CircularProgress color="secondary" size={120} />
            </div>
          ) : null}

          {generateSheet()}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
