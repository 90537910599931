import React, { useEffect, useState } from 'react';
import styles from './Topnav.module.scss';
import Navlist from './Navlist';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as Logo } from '../assets/images/MonkeyNeonBanner.svg';
import { ReactComponent as AccountIcon } from '../assets/icons/baseline-account_circle-24px.svg';
import { ReactComponent as MenuIcon } from '../assets/icons/baseline-menu-24px.svg';
import { ReactComponent as Clear } from '../assets/icons/baseline-clear-24px.svg';
import Cookies from 'universal-cookie';

export default () => {
  const [expand, toggle] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isMobile, checkIsMobile] = useState(window.innerWidth < 1024);

  const openMenu = (ev: any) => {
    setMenuAnchor(ev.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const UserMenu = () => {
    return (
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    );
  };

  const logout = () => {
    window.sessionStorage.clear();
    window.location.reload();
  };

  const handleWindowSizeChange = () => {
    checkIsMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  });

  if (isMobile) {
    return (
      <div
        className={expand ? styles.topnav_full : styles.topnav}
        role="navigation"
      >
        <div className={styles.link_group}>
          <IconButton aria-label="Toggle Navbar">
            {expand ? (
              <Clear
                className={styles.white_icon}
                onClick={() => toggle(!expand)}
              />
            ) : (
              <MenuIcon
                className={styles.white_icon}
                onClick={() => toggle(!expand)}
              />
            )}
          </IconButton>
          <Logo />
          <div className={styles.icon_group}>
            <p className={styles.profile_text}>ADMIN</p>
            <IconButton aria-label="Account Button" onClick={openMenu}>
              <AccountIcon className={styles.white_icon} />
            </IconButton>
          </div>
        </div>
        <div className={expand ? styles.link_group_mobile : styles.hidden}>
          <Navlist />
        </div>
        <UserMenu />
      </div>
    );
  } else {
    return (
      <div className={styles.topnav} role="navigation">
        <div className={styles.link_group}>
          <Logo className={styles.logo} />
          <Navlist />
          <div className={styles.icon_group}>
            <p className={styles.profile_text}>ADMIN</p>
            <IconButton aria-label="Account Button" onClick={openMenu}>
              <AccountIcon className={styles.white_icon} />
            </IconButton>
          </div>
        </div>
        <UserMenu />
      </div>
    );
  }
};
