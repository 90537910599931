export const delay = <T>(time: number, fn?: Promise<T>): Promise<T> => {
  return new Promise((res, rej) => {
    let isFinished = false;
    let isTimeout = false;
    let value: T;
    setTimeout(() => {
      isTimeout = true;
      if (fn) {
        if (isFinished) {
          res(value);
        }
      } else {
        res();
      }
    }, time);
    if (fn) {
      fn.then(val => {
        isFinished = true;
        value = val;
        if (isTimeout) {
          res(val);
        }
      }).catch(error => rej(error));
    }
  });
};

export const downloadCanvas = (canvas: HTMLCanvasElement, filename: string) => {
  const a = document.createElement('a');
  a.setAttribute(
    'href',
    canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
  );
  a.setAttribute('download', filename);
  a.click();
};
