import React, { useState, FormEvent, useEffect, useRef } from 'react';
import styles from './MigrateBranchSheetDialog.module.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useLazyQuery, LazyQueryHookOptions } from '@apollo/react-hooks';
import {
  decodeLessonCode,
  lessonQuery,
  checkupQuery,
  testQuery,
} from '../../utils/branch';
import { migrateBranchSheet } from './MigrateBranchService';

export default () => {
  const [branchCode, setBranchCode] = useState<string>('');
  const [newCode, setNewCode] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [fontSize, setFontSize] = useState<string>('18');
  const [imageWidth, setImageWidth] = useState<string>('40');
  const [imageAlign, setImageAlign] = useState<string>('');
  const [isPreview, setIsPreview] = useState(false);

  const [
    getLesson,
    { loading: lessonLoading, data: lessonData },
  ] = useLazyQuery(lessonQuery, {
    fetchPolicy: 'cache-and-network',
  } as LazyQueryHookOptions);
  const [
    getCheckup,
    { loading: checkupLoading, data: checkupData },
  ] = useLazyQuery(checkupQuery, {
    fetchPolicy: 'cache-and-network',
  } as LazyQueryHookOptions);
  const [getTest, { loading: testLoading, data: testData }] = useLazyQuery(
    testQuery,
    { fetchPolicy: 'cache-and-network' } as LazyQueryHookOptions
  );
  const [queryStatus, setQueryStatus] = useState('No queries');
  const divEl = useRef<HTMLDivElement>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsPreview(false);
    const { division, level, type, order } = decodeLessonCode(branchCode);
    if (type === 'L') {
      getLesson({ variables: { division, level, order } });
      setType('L');
    } else if (type === 'C') {
      getCheckup({ variables: { division, level, order } });
      setType('C');
    } else if (type === 'T') {
      getTest({ variables: { division, level, order } });
      setType('T');
    }
  };

  const handleReset = (e: FormEvent) => {
    e.preventDefault();
    setBranchCode('');
    setNewCode('');
    setFontSize('18');
    setImageWidth('70');
    setImageAlign('');
  };

  const preview = (e: FormEvent) => {
    e.preventDefault();
    setIsPreview(true);
    const { division, level, type, order } = decodeLessonCode(branchCode);
    if (type === 'L') {
      getLesson({ variables: { division, level, order } });
      setType('L');
    } else if (type === 'C') {
      getCheckup({ variables: { division, level, order } });
      setType('C');
    } else if (type === 'T') {
      getTest({ variables: { division, level, order } });
      setType('T');
    }
  };

  useEffect(() => {
    if (lessonLoading || checkupLoading || testLoading) {
      setQueryStatus('Loading...');
    } else {
      if (type === 'L') {
        if (divEl.current) {
          migrateBranchSheet(
            'sheet',
            newCode,
            lessonData.lessons[0],
            divEl.current,
            fontSize,
            imageWidth,
            imageAlign,
            isPreview
          );
        }
      } else if (type === 'C') {
        if (divEl.current) {
          migrateBranchSheet(
            'checkup',
            newCode,
            checkupData.checkups[0],
            divEl.current,
            fontSize,
            imageWidth,
            imageAlign,
            isPreview
          );
        }
      } else if (type === 'T') {
        if (divEl.current) {
          migrateBranchSheet(
            'test',
            newCode,
            testData.tests[0],
            divEl.current,
            fontSize,
            imageWidth,
            imageAlign,
            isPreview
          );
        }
      } else {
        setQueryStatus('No queries');
      }
    }
  }, [lessonLoading, checkupLoading, testLoading]);

  return (
    <React.Fragment>
      <p>{queryStatus}</p>
      <form autoComplete="off" onSubmit={handleSubmit} onReset={handleReset}>
        <TextField
          required
          id="standard-required"
          label="Branch sheet code"
          className={styles.textField}
          value={branchCode}
          onChange={(e: React.ChangeEvent) =>
            setBranchCode((e.target as HTMLInputElement).value)
          }
          margin="normal"
          placeholder="MK-G-1"
        />
        <TextField
          required
          id="standard-required"
          label="New sheet code"
          className={styles.textField}
          value={newCode}
          onChange={(e: React.ChangeEvent) =>
            setNewCode((e.target as HTMLInputElement).value)
          }
          margin="normal"
          placeholder="MN-ZZB01(0.0.0)"
        />
        <TextField
          required
          id="standard-required"
          label="Font size"
          className={styles.textField}
          value={fontSize}
          onChange={(e: React.ChangeEvent) =>
            setFontSize((e.target as HTMLInputElement).value)
          }
          margin="normal"
          placeholder="18"
        />
        <TextField
          required
          id="standard-required"
          label="Image width"
          className={styles.textField}
          value={imageWidth}
          onChange={(e: React.ChangeEvent) =>
            setImageWidth((e.target as HTMLInputElement).value)
          }
          margin="normal"
          placeholder="70"
        />
        <TextField
          required
          id="standard-required"
          label="Image align"
          className={styles.textField}
          value={imageAlign}
          onChange={(e: React.ChangeEvent) =>
            setImageAlign((e.target as HTMLInputElement).value)
          }
          margin="normal"
          placeholder="1,2,3"
        />
        <div className={styles.wrapper}>
          <Button type="reset">Reset</Button>
          <Button type="submit" variant="contained" color="primary">
            Migrate
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={preview}
          >
            Preview
          </Button>
        </div>
      </form>
      <div ref={divEl} className={styles.preview}></div>
    </React.Fragment>
  );
};
