import React, { useState, useEffect } from 'react';
import { Student, getPortfolio, Portfolios } from '../services/student';
import styles from './PortfolioList.module.scss';
import { AxiosPromise } from 'axios';
import { Subject } from '../state/ducks/sheet/types';
import { ReactComponent as PortfolioBox } from '../assets/images/portfolioBox.svg';

interface ListProps {
  student: Student;
}

export default (props: ListProps) => {
  const student = props.student;
  const [math, setMath] = useState('-');
  const [phy, setPhy] = useState('-');
  const [chem, setChem] = useState('-');

  const promiseWithError = async (promise: AxiosPromise) => {
    try {
      const { data } = await promise;
      return { error: null, data: data };
    } catch (err) {
      return { error: err, data: null };
    }
  };

  useEffect(() => {
    promiseWithError(getPortfolio(student.ID)).then(cb => {
      if (!cb.error) {
        const ports = cb.data as Portfolios;
        for (const i of ports.sheets) {
          if (i) {
            switch (i.sheet.subject) {
              case Subject.MATH:
                setMath(i.sheet.code);
                break;
              case Subject.PHYSICS:
                setPhy(i.sheet.code);
                break;
              case Subject.CHEMISTRY:
                setChem(i.sheet.code);
                break;
              default:
                break;
            }
          }
        }
      }
    });
  }, [student]);
  return (
    <div className={styles.container}>
      <p className={styles.name}>
        {student.ID}: {student.nickname} {student.firstname}
      </p>
      <div className={styles.box}>
        <PortfolioBox style={{ width: '100%' }} />
        <div className={styles.content}>
          <p className={styles.math}>{math}</p>
          <p className={styles.phy}>{phy}</p>
          <p className={styles.chem}>{chem}</p>
        </div>
      </div>
    </div>
  );
};
